<template>
  <div class="footer">
    <p>Copyright 2021</p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style>

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #C1C1C1;
  background-color: #132544;
}

</style>