import Connecter from '../components/Connecter.vue'
import SignUp from '../components/SignUp.vue'
import Login from '../components/Login.vue'
import Profil from '../components/Profil.vue'
import UsersAll from '../components/UsersAll.vue'
import ConsultMenu from '../components/ConsultMenu.vue'
import AjoutMenu from '../components/AjoutMenu.vue'
import ModifMenu from '../components/ModifMenu.vue'
import CreationPlat from '../components/Plats.vue'
import SaisieMenuSemaine from '../components/SaisieMenuSemaine.vue'
import SaisieMenuSemaine1 from '../components/SaisieMenuSemaine1.vue'
import SaisieMenuSemaine2 from '../components/SaisieMenuSemaine2.vue'
import SaisieMenuSemaine3 from '../components/SaisieMenuSemaine3.vue'
import SaisieMenuSemaine4 from '../components/SaisieMenuSemaine4.vue'
import ModifMenuSemaine from '../components/ModifMenuSemaine.vue'
import ModifMenuSemaine1 from '../components/ModifMenuSemaine1.vue'
import ModifMenuSemaine2 from '../components/ModifMenuSemaine2.vue'
import ModifMenuSemaine3 from '../components/ModifMenuSemaine3.vue'
import ModifMenuSemaine4 from '../components/ModifMenuSemaine4.vue'
import CreationTarif from '../components/CreationTarif.vue'
import ListeTarif from '../components/ListeTarif.vue'
import ModificationTarif1 from '../components/ModificationTarif1.vue'
import ModificationTarif2 from '../components/ModificationTarif2.vue'
import ModificationTarif3 from '../components/ModificationTarif3.vue'
import ModificationTarif4 from '../components/ModificationTarif4.vue'
import ModificationTarif5 from '../components/ModificationTarif5.vue'
import VisueMenuSemaine1 from '../components/VisueMenuSemaine1.vue'
import VisueMenuSemaine2 from '../components/VisueMenuSemaine2.vue'
import VisueMenuSemaine3 from '../components/VisueMenuSemaine3.vue'
import VisueMenuSemaine4 from '../components/VisueMenuSemaine4.vue'
import RecapCommande from '../components/RecapCommande.vue'
import UtilisateurSaisie from '../components/UtilisateurSaisie.vue'
import EditionMenu from '../components/VisueMenuSemaine.vue'



export default [
  {path: '/', component: Login},
  {path: '/connecter', component: Connecter},
  {path: '/signup', component: SignUp},
  {path: '/usersAll', component: UsersAll},
  {path: '/profil/:id', component: Profil},
  {path: '/ConsultMenu', component: ConsultMenu},
  {path: '/AjoutMenu', component: AjoutMenu},
  {path: '/ModifMenu', component: ModifMenu},
  {path: '/creationPlat', component: CreationPlat},
  {path: '/SaisieMenuSemaine', component: SaisieMenuSemaine},
  {path: '/SaisieMenuSemaine1', component: SaisieMenuSemaine1},
  {path: '/SaisieMenuSemaine2', component: SaisieMenuSemaine2},
  {path: '/SaisieMenuSemaine3', component: SaisieMenuSemaine3},
  {path: '/SaisieMenuSemaine4', component: SaisieMenuSemaine4},
  {path: '/ModifMenuSemaine', component: ModifMenuSemaine},
  {path: '/ModifMenuSemaine1', component: ModifMenuSemaine1},
  {path: '/ModifMenuSemaine2', component: ModifMenuSemaine2},
  {path: '/ModifMenuSemaine3', component: ModifMenuSemaine3},
  {path: '/ModifMenuSemaine4', component: ModifMenuSemaine4},
  {path: '/CreationTarif', component: CreationTarif},
  {path: '/ListeTarif', component: ListeTarif},
  {path: '/ModificationTarif1', component: ModificationTarif1},
  {path: '/ModificationTarif2', component: ModificationTarif2},
  {path: '/ModificationTarif3', component: ModificationTarif3},
  {path: '/ModificationTarif4', component: ModificationTarif4},
  {path: '/ModificationTarif5', component: ModificationTarif5},
  {path: '/VisueMenuSemaine1', component: VisueMenuSemaine1},
  {path: '/VisueMenuSemaine2', component: VisueMenuSemaine2},
  {path: '/VisueMenuSemaine3', component: VisueMenuSemaine3},
  {path: '/VisueMenuSemaine4', component: VisueMenuSemaine4},
  {path: '/RecapCommande', component: RecapCommande},
  {path: '/UtilisateurSaisie', component: UtilisateurSaisie},
  {path: '/EditionMenu', component: EditionMenu},

]