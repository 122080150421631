<template>
  <div class="container misePage pt-5 pb-5">
    <div class="card">
      <div class="card-header text-center">
        <h1>Création d'un plat</h1>
        
      </div>
      <!-- /// Formulaire pour le Login logiciel cantine \\\ -->
      <form action="" class="card-body  mt-3 w-75 ml-auto mr-auto">
        <div>
          <select v-model="categorie">
            <option disabled value="">Choisissez le type de plat</option>
            <option>Entrées</option>
            <option>Main Course</option>
            <option>Accompagnements</option>
            <option>Fromages</option>
            <option>Desserts</option>
            <option>Pain</option>
          </select>
          <br>
        </div>
        <div class="form-group mt-3">
          <label class="ml-2" for="nom_plat">Entrez le nom du plat</label>
          <input v-model="nom_plat" type="text" id="nom_plat" class="form-control">
        </div>
        <div class="form-group">
          <label class="ml-2" for="complet">Plat complet</label>
          <input v-model="complet" type="checkbox" id="complet" class="form-control">
        </div>
        <div class="form-group">
          <label class="ml-2" for="porc">Contient du porc</label>
          <input v-model="porc" type="checkbox" id="porc" class="form-control">
        </div>
        <div class="form-group text-center">
          <button  class="btn btn-primary" @click.prevent="creationPlat()">Enregister le plat</button>
        </div>
      </form>
    </div>
      
  </div>
  
</template>

<script>
import axios from 'axios'
import store from '../store/index'
import Swal from 'sweetalert2'

export default {
  name: 'Plats',
  data () {
    return{
      categoriePlat: ['Entrées', 'Main Course', 'Accompagnements', 'Fromages', 'Desserts', 'Pain'],
      categorie: '',
      complet: false,
      porc: false,
      nom_plat: '',
    }
  },
  methods: {
      creationPlat() {

        if (this.categorie != ""){

          axios.post('http://localhost:3000/plats', {
              categorie: this.categorie,
              complet: this.complet,
              porc: this.porc,
              nom_plat: this.nom_plat,

            },
              { headers: { Authorization: `Bearer ${store.state.token}` },}
            )
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Menu publié',
                showConfirmButton: false,
                timer: 2000
              })
              window.location.reload()
            })
            .catch(error => {console.log("Menu non publié: ", error.response);
              Swal.fire({
                title: "Une erreur est survenue",
                icon: "error",
                timer: 2500,
                showConfirmButton: false,
                timerProgressBar: true
              }) 
            })
        } else {

           
          Swal.fire({
                title: "La catégorie n'est pas renseignée",
                icon: "error",
                timer: 2500,
                showConfirmButton: false,
                timerProgressBar: true
              })


          }
        },


      }
}
</script>

<style scoped>
.container {
  height: 89vh;
}
.card {
  opacity: 0.9;
}
.misePage {
  margin: 95px auto 0px auto;
}
</style>