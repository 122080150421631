<template>
  
  <div class="container misePage pt-2 pb-5">
    
    <!-- Affichage si connecter en temps que administrateur  -->
    <div class="card pt-4 pl-4 pr-4" v-if="$store.state.isModerateur">
      <div class="card-header text-center">
        <h1>Vous êtes connecter en temps que administrateur</h1>
      </div>

        
      <div class="card-deck mt-3 mb-3">
        <!-- /// Gestion des utilisateurs \\\ -->
        <div class="card text-center">
            <div class="card-header">
              <h4>Gestion des Utilisateurs</h4>   
            </div>
            <div class="card-body" >
                <p></p>
                <button @click="creationUser()" class="btn btn-primary mt-2" >Création Utilisateur</button>
                <p></p>
                <button @click="modificationUser()" class="btn btn-warning mt-2" >Modification Utilisateur</button>
            </div>
        </div>
        <!-- /// Gestion des menus \\\ -->
        <div class="card text-center">
          <div class="card-header">
            <h4>Gestion des menus</h4>   
          </div>
          <div class="card-body" >
            <p></p>
            <button @click="AjoutMenu()" class="btn btn-primary mt-2" >Création des menu</button>
            <p></p>
            <button @click="ModifMenu()" class="btn btn-warning mt-2" >Modification des menus</button>
            <p></p>
            <button @click="CreationPlat()" class="btn btn-primary mt-2" >Création plats</button>
          </div>
        </div>
        <!-- /// Saisie des menus \\\ -->
        <div class="card text-center">
          <div class="card-header">
            <h4>Saisie des menus</h4>   
          </div>
          <div class="card-body" >
            <p></p>
            <button @click="SaisieMenuSemaine()" class="btn btn-primary mt-2" >Saisie des menus pour une semaine</button>
            <p></p>
            <button @click="ModifMenuSemaine()" class="btn btn-warning mt-2" >Modif des menus pour une semaine</button>
            <p></p>
            <button @click="EditionMenu()" class="btn btn-success ml-5 mr-5 mt-2" >Edition des menus</button>
          </div>
        </div>
    </div>
    <!-- 2° rangée de carte -->
    <div class="card-deck mt-3 mb-3">
        <div class="card text-center">
            <!-- /// Affichage pour l'Avatar du profil et modification si on est l'utilisateur \\\ -->
            <div class="card-header">
              <h4>Récapitulatif commande</h4>   
            </div>
            <div class="card-body" >
  
                <button @click="RecapCommande()" class="btn btn-primary ml-5 mr-5 mt-2" >Récapitulatif commande</button>
   
                <!-- <button @click="controleSaisie()" class="btn btn-primary ml-5 mr-5 mt-2" >Contrôle saisie</button> -->
            </div>
        </div>

    </div>

    <!-- 3° rangée de carte -->

    <div class="card-deck mt-3 mb-3">

        <!-- /// Gestion des tarifs \\\ -->
        <div class="card text-center">
          <div class="card-header">
            <h4>Gestion des paiements et tarif</h4>   
          </div>
          <div class="card-body mb-5" >

              <button @click="CreationTarif()" class="btn btn-primary ml-5 mr-5 mt-2" >Création d'un tarif</button>
 
              <button @click="ListeTarif()" class="btn btn-primary ml-5 mr-5 mt-2" >Liste des tarifs</button>

              <!-- <button @click="updateProfil()" class="btn btn-primary ml-5 mr-5 mt-2" >Gestion des paiements</button> -->
          </div>

        </div>
    </div>
      
    </div>

    <!-- Affichage si on est un utilisateur  -->
    <div class="card p-4" v-if="!$store.state.isModerateur">
      <div class="card-header text-center">
        <h1>Bienvenue {{ $store.state.prenom }} {{ $store.state.nom }}</h1>
      </div>

        
      <div class="card-deck mt-3 mb-3">
        <div class="card text-center">
            <!-- /// Affichage pour l'utilisateur des 4 semaines de menus \\\ -->
            <div class="card-header">
              <h2>Commande par semaine</h2>   
            </div>
            <div class="card-body" >
                
               
              
                
                  <button v-if="date_validite1 < dateCourante && menuActifSemaine1 === true" @click="SaisieMenuSemaine1()" class="btn btn-lg btn-danger menu mt-5 mb-5 ml-5 mr-5" >Edition du menu de la semaine {{ semaine1 }}</button>
                  <button v-else-if="semaine1Saisie == 1" @click="SaisieMenuSemaine1()" class="btn btn-lg btn-warning menu mt-5 mb-5 ml-5 mr-5" >Modification de la semaine {{ semaine1 }}</button>
                  <button v-else-if="menuActifSemaine1 === false" @click="SaisieMenuSemaine1()" class="btn btn-lg btn-secondary menu mt-5 mb-5 ml-5 mr-5" >Saisie de la semaine {{ semaine1 }}</button>
                  <button v-else @click="SaisieMenuSemaine1()" class="btn btn-lg btn-success menu mt-5 mb-5 ml-5 mr-5" >Saisie de la semaine {{ semaine1 }}</button>
                
                  
                  <button v-if="date_validite2 < dateCourante && menuActifSemaine2 === true" @click="SaisieMenuSemaine2()" class="btn btn-lg btn-danger menu mt-5 mb-5 ml-5 mr-5" >Edition du menu de la semaine {{ semaine2 }}</button>
                  <button v-else-if="semaine2Saisie == 1" @click="SaisieMenuSemaine2()" class="btn btn-lg btn-warning menu mt-5 mb-5 ml-5 mr-5" >Modification de la semaine {{ semaine2 }}</button>
                  <button v-else-if="menuActifSemaine2 === false" @click="SaisieMenuSemaine2()" class="btn btn-lg btn-secondary menu mt-5 mb-5 ml-5 mr-5" >Saisie de la semaine {{ semaine2 }}</button>
                  <button v-else @click="SaisieMenuSemaine2()" class="btn btn-lg btn-success menu mt-5 mb-5 ml-5 mr-5" >Saisie de la semaine {{ semaine2 }}</button>
                

                
                  <button v-if="date_validite3 < dateCourante && menuActifSemaine3 === true" @click="SaisieMenuSemaine3()" class="btn btn-lg btn-danger menu mt-5 mb-5 ml-5 mr-5" >Edition du menu de la semaine {{ semaine3 }}</button>
                  <button v-else-if="semaine3Saisie == 1" @click="SaisieMenuSemaine3()" class="btn btn-lg btn-warning menu mt-5 mb-5 ml-5 mr-5" >Modification de la semaine {{ semaine3 }}</button>
                  <button v-else-if="menuActifSemaine3 === false" @click="SaisieMenuSemaine3()" class="btn btn-lg btn-secondary menu mt-5 mb-5 ml-5 mr-5" >Saisie de la semaine {{ semaine3 }}</button>
                  <button v-else @click="SaisieMenuSemaine3()" class="btn btn-lg btn-success menu mt-5 mb-5 ml-5 mr-5" >Saisie de la semaine {{ semaine3 }}</button>



                  <button v-if="date_validite4 < dateCourante && menuActifSemaine4 === true" @click="SaisieMenuSemaine4()" class="btn btn-lg btn-danger menu mt-5 mb-5 ml-5 mr-5" >Edition du menu de la semaine {{ semaine4 }}</button>
                  <button v-else-if="semaine4Saisie == 1" @click="SaisieMenuSemaine4()" class="btn btn-lg btn-warning menu mt-5 mb-5 ml-5 mr-5" >Modification de la semaine {{ semaine4 }}</button>
                  <button v-else-if="menuActifSemaine4 === false" @click="SaisieMenuSemaine4()" class="btn btn-lg btn-secondary menu mt-5 mb-5 ml-5 mr-5" >Saisie de la semaine {{ semaine4 }}</button>
                  <button v-else @click="SaisieMenuSemaine4()" class="btn btn-lg btn-success menu mt-5 mb-5 ml-5 mr-5" >Saisie de la semaine {{ semaine4 }}</button>
                
                
                
            </div>
        </div>

    </div>

      
    </div>
  </div>

  
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import store from '../store/index'


export default {
  name: 'Connecter',

  data () {
    return {
      semaineSaisie1: '',
      semaineSaisie2: '',
      semaineSaisie3: '',
      semaineSaisie4: '',
      dateCourante: moment(String(new Date())).format('YYYY-MM-DD'),
      semaine1: moment(String(new Date())).format('W-YYYY'),
      semaine2: moment(String(new Date())).add(7, 'days').format('W-YYYY'),
      semaine3: moment(String(new Date())).add(14, 'days').format('W-YYYY'),
      semaine4: moment(String(new Date())).add(21, 'days').format('W-YYYY'),
      semaine1Saisie: 0,
      semaine2Saisie: 0,
      semaine3Saisie: 0,
      semaine4Saisie: 0,
      date_validite1: '',
      date_validite2: '',
      date_validite3: '',
      date_validite4: '',
      menuActifSemaine1: false,
      menuActifSemaine2: false,
      menuActifSemaine3: false,
      menuActifSemaine4: false,
    }
  },

  methods: {
    creationUser(){
      this.$router.push('/signup')
    },
    modificationUser(){
      this.$router.push('/usersAll')
    },
    AjoutMenu(){
      this.$router.push('/AjoutMenu')
    },
    ModifMenu(){
      this.$router.push('/ModifMenu')
    },
    ConsultMenu(){
      this.$router.push('/ConsultMenu')
    },
    CreationPlat(){
      this.$router.push('/CreationPlat')
    },
    SaisieMenuSemaine(){
      this.$router.push('/SaisieMenuSemaine')
    },
    ModifMenuSemaine(){
      this.$router.push('/ModifMenuSemaine')
    },
    CreationTarif(){
      this.$router.push('/CreationTarif')
    },
    ListeTarif(){
      this.$router.push('/ListeTarif')
    },
    RecapCommande(){
      this.$router.push('/RecapCommande')
    },
    controleSaisie(){
      this.$router.push('/UtilisateurSaisie')
    },
    EditionMenu(){
      this.$router.push('/EditionMenu')
    },



    
    // gestion des 4 semaines pour la saisie des utilisateurs 
    // afin de faire une direction vers la saisie ou la modification si une saisie existe déja
    // 


    SaisieMenuSemaine1(){
      if(this.dateCourante > this.date_validite1 && this.menuActifSemaine1){
        console.log("Visue de la commande");
        this.$router.push('/VisueMenuSemaine1') 

      } else if 
        (this.dateCourante > this.date_validite1 && !this.menuActifSemaine1) {
        console.log("modif saisie");
        this.$router.push('/connecter')
        
      } else if 
        (this.semaine1Saisie === 1 ) {
        console.log("modif saisie");
        this.$router.push('/ModifMenuSemaine1')
        
      } else {
        console.log("saisie de la commande");
        this.$router.push('/SaisieMenuSemaine1')
      }
    },

    SaisieMenuSemaine2(){
      if(this.dateCourante > this.date_validite2 && this.menuActifSemaine2){
        console.log("Visue de la commande");
        this.$router.push('/VisueMenuSemaine2') 

      } else if 
        (this.dateCourante > this.date_validite2 && !this.menuActifSemaine2) {
        console.log("modif saisie");
        this.$router.push('/connecter')
        
      } else if 
        (this.semaine2Saisie === 1 ) {
        console.log("modif saisie");
        this.$router.push('/ModifMenuSemaine2')
        
      } else {
        console.log("saisie de la commande");
        this.$router.push('/SaisieMenuSemaine2')
      }
    },
    SaisieMenuSemaine3(){
      if(this.dateCourante > this.date_validite3 && this.menuActifSemaine3){
        console.log("Visue de la commande");
        this.$router.push('/VisueMenuSemaine3') 

      } else if 
        (this.dateCourante > this.date_validite3 && !this.menuActifSemaine3) {
        console.log("modif saisie");
        this.$router.push('/connecter')
        
      } else if 
        (this.semaine3Saisie === 1 ) {
        console.log("modif saisie");
        this.$router.push('/ModifMenuSemaine3')
        
      } else {
        console.log("saisie de la commande");
        this.$router.push('/SaisieMenuSemaine3')
      }
    },
    SaisieMenuSemaine4(){
      if(this.dateCourante > this.date_validite4 && this.menuActifSemaine4){
        console.log("Visue de la commande");
        this.$router.push('/VisueMenuSemaine4') 

      } else if 
        (this.dateCourante > this.date_validite4 && !this.menuActifSemaine4) {
        console.log("modif saisie");
        this.$router.push('/connecter')
        
      } else if 
        (this.semaine4Saisie === 1 ) {
        console.log("modif saisie");
        this.$router.push('/ModifMenuSemaine4')
        
      } else {
        console.log("saisie de la commande");
        this.$router.push('/SaisieMenuSemaine4')
      }
    },

    
  },

  created() {
    this.semaineSaisie1 = this.semaine1
    this.semaineSaisie2 = this.semaine2
    this.semaineSaisie3 = this.semaine3
    this.semaineSaisie4 = this.semaine4
    this.uniqueSaisie1 = store.state.userId + this.semaineSaisie1
    this.uniqueSaisie2 = store.state.userId + this.semaineSaisie2
    this.uniqueSaisie3 = store.state.userId + this.semaineSaisie3
    this.uniqueSaisie4 = store.state.userId + this.semaineSaisie4
    console.log(this.dateCourante);
  },
  mounted() {
    //Récupération de la 1° semaine de saisie
    axios.get(`http://localhost:3000/commande/${this.uniqueSaisie1}`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
    )
    .then(response => {
      console.log('passage dans le IF');
      if(response.data.modifcde[0].uniqueSaisie == this.uniqueSaisie1 ) {
        console.log("passage à l'intérieur du IF");
      this.semaine1Saisie = 1  
      console.log(this.semaine1Saisie);
      }
    })
    .catch(error => {console.log('erreur import menu semaine 1:', error.response);})
    //Récupération de la 2° semaine de saisie
    axios.get(`http://localhost:3000/commande/${this.uniqueSaisie2}`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
    )
    .then(response => {
      console.log('passage dans le IF');
      if(response.data.modifcde[0].uniqueSaisie == this.uniqueSaisie2 ) {
        console.log("passage à l'intérieur du IF");
      this.semaine2Saisie = 1  
      console.log(this.semaine2Saisie);
      }
    })
    .catch(error => {console.log('erreur import menu semaine 2:', error.response);})
    //Récupération de la 3° semaine de saisie
    axios.get(`http://localhost:3000/commande/${this.uniqueSaisie3}`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
    )
    .then(response => {
      console.log('passage dans le IF');
      if(response.data.modifcde[0].uniqueSaisie == this.uniqueSaisie3 ) {
        console.log("passage à l'intérieur du IF");
      this.semaine3Saisie = 1  
      console.log(this.semaine3Saisie);
      }
    })
    .catch(error => {console.log('erreur import menu:', error.response);})
    //Récupération de la 4° semaine de saisie
    axios.get(`http://localhost:3000/commande/${this.uniqueSaisie4}`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
    )
    .then(response => {
      console.log('passage dans le IF');
      if(response.data.modifcde[0].uniqueSaisie == this.uniqueSaisie4 ) {
        console.log("passage à l'intérieur du IF");
      this.semaine4Saisie = 1  
      console.log(this.semaine4Saisie);
      }
    })
    .catch(error => {console.log('erreur import menu:', error.response);})

    //Récupération du menu actif de la semaine 1
    axios.get(`http://localhost:3000/menu/${this.semaineSaisie1}`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
    .then(response => {
      console.log('passage dans le IF');
      this.menuActifSemaine1 = response.data.menus[0].menuActif
      this.date_validite1 = response.data.menus[0].date_validite
    })
    .catch(error => {console.log('erreur import menu:', error.response);})
    //Récupération du menu actif de la semaine 2
    axios.get(`http://localhost:3000/menu/${this.semaineSaisie2}`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
    .then(response => {
      console.log('passage dans le IF');
      this.menuActifSemaine2 = response.data.menus[0].menuActif
      this.date_validite2 = response.data.menus[0].date_validite
      console.log(this.date_validite2);
    })
    .catch(error => {console.log('erreur import menu:', error.response);})
    //Récupération du menu actif de la semaine 3
    axios.get(`http://localhost:3000/menu/${this.semaineSaisie3}`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
    .then(response => {
      console.log('passage dans le IF');
      this.menuActifSemaine3 = response.data.menus[0].menuActif
      this.date_validite3 = response.data.menus[0].date_validite
    })
    .catch(error => {console.log('erreur import menu:', error.response);})
    //Récupération du menu actif de la semaine 4
    axios.get(`http://localhost:3000/menu/${this.semaineSaisie4}`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
    .then(response => {
      console.log('passage dans le IF');
      this.menuActifSemaine4 = response.data.menus[0].menuActif
      this.date_validite4 = response.data.menus[0].date_validite
    })
    .catch(error => {console.log('erreur import menu:', error.response);})

  }
}
</script>

<style>
.container {
  height: 95vh;
  
}
.card {
  opacity: 0.95;
  
}
.misePage {
  margin: 95px auto 0px auto;


}

.menu {
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 15px;

}
</style>