<template>
  <div class="container misePage pt-5 pb-5">
    <div class="card">
      <div class="card-header text-center">
        <h1>Connexion à la cantine SLTS</h1>
      </div>
      <!-- /// Formulaire pour le Login logiciel cantine \\\ -->
      <form action="" class="card-body  mt-3 w-75 ml-auto mr-auto">
        <div class="form-group">
          <label class="ml-2" for="identifiant">Entrez votre identifiant</label>
          <input v-model="identifiant" type="text" id="identifiant" class="form-control">
        </div>
        <div class="form-group">
          <label class="ml-2" for="password">Entrez votre mot de passe</label>
          <input v-model="password" type="password" id="password" class="form-control">
        </div>
        <div class="form-group text-center">
          <button class="btn btn-primary" @click.prevent="login()">Se connecter</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'


export default {
  name: 'Login',
  data () {
    return{
      password: '',
      identifiant: '',
    }
  },
  methods: {
        login() {
          axios.post('http://localhost:3000/login', {
            password: this.password,
            identifiant: this.identifiant,
            })
            .then(response => {
              if (response.data.userId == undefined) {
                Swal.fire({
                  title: "L'identifiant ou le mot de passe n'est pas conforme",
                  icon: "error",
                  timer: 5000,
                  showConfirmButton: false,
                  timerProgressBar: true
                })
                window.location.reload()
                
              } else {
                  this.$store.dispatch('setToken', response.data.token)
                  this.$store.dispatch('setUser', response.data.userId)
                  this.$store.dispatch('setModerateur', response.data.ismoderateur)
                  this.$store.dispatch('setNom', response.data.nom)
                  this.$store.dispatch('setPrenom', response.data.prenom)
                  this.$router.push('/connecter')
                  Swal.fire({
                    icon: 'success',
                    title: 'Bravo, bienvenue sur le logiciel de cantine de SLTS',
                    showConfirmButton: false,
                    timer: 2000
                  })
              }
            })
            .catch(error => {console.log("Une erreur s'est produite front: ", error.response);})
        },


        // login() {
        //   axios.post('http://localhost:3000/login', {
        //     password: this.password,
        //     identifiant: this.identifiant,
        //     })
        //     .then(response => {
        //       console.log("la réponse de login est " + response.data.userId);
        //       this.$store.dispatch('setToken', response.data.token)
        //       this.$store.dispatch('setUser', response.data.userId)
        //       this.$store.dispatch('setModerateur', response.data.ismoderateur)
        //       this.$store.dispatch('setNom', response.data.nom)
        //       this.$store.dispatch('setPrenom', response.data.prenom)
        //       this.$router.push('/connecter')
        //     })
        //     .catch(error => {console.log("Une erreur s'est produite front: ", error.response);})
        // },
      }
}
</script>

<style scoped>
.container {
  height: 89vh;
}
.card {
  opacity: 0.9;
}
.misePage {
  margin: 95px auto 0px auto;
}
</style>