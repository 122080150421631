<template>
    <div class="container misePage pt-2 pb-5">
        <div class="card  pt-4 pl-4 pr-4">
            <div class="card-header text-center">
                <div>
                    <h1>Liste des tarifs</h1>
                    <button @click="retour()" class="btn btn-success float-right" >Retour Accueil</button>
                </div>
            </div>
                <div class="card-deck mt-3 mb-3">
                    <div class="card text-center">
                        <div class="card-header pt-4">
                            <button v-if="!visible" @click="afficheListeTarif()" class="btn btn-success float-center" >Affichage des 5 derniers tarifs</button>
                            
                            
                        </div>
                        <div class="pt-2">
                           

                            <div v-if="visible">
                                <div>
                                    <div class="card-body text-center" >
                                        <!-- gestion des tarifs -->
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h4 class="mt-2">Semaine</h4>
                                            </div>
                                            <div class="col-3">
                                                <h4 class="mt-2">Date création</h4>
                                            </div>
                                            <div class="col-3">
                                                <h4 class="mt-2">Modification</h4>
                                            </div>
                                        </div>
                                        <!-- Information sur la dernière saisie -->
                                        <div class="row pt-2">
                                            <div class="col-2"></div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2">{{ allTarif[0].semaine_tarif }}</h5>
                                            </div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2">{{ semaine1 }}</h5>
                                            </div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <!-- <router-link :to="`/ModificationTarif/${allTarif[0].tarifId}`">
                                                    <button class="mt-1">📝</button>
                                                </router-link> -->
                                               <h5 class="text-center mt-2"><button class="btn btn-danger btn-floating" @click.prevent="modifTarifSemaine1()"><i class="fas fa-magic"></i></button></h5>
                                            </div>
                                        </div>
                                        <!-- Information sur la deuxième saisie en partant de la fin -->
                                        <div class="row pt-2">
                                            <div class="col-2"></div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2">{{ allTarif[1].semaine_tarif }}</h5>
                                            </div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2">{{ semaine2 }}</h5>
                                            </div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2"><button class="btn btn-danger btn-floating" @click.prevent="modifTarifSemaine2()"><i class="fas fa-magic"></i></button></h5>
                                            </div>
                                        </div>
                                        <!-- Information sur la troisième saisie en partant de la fin -->
                                        <div class="row pt-2">
                                            <div class="col-2"></div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2">{{ allTarif[2].semaine_tarif }}</h5>
                                            </div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2">{{ semaine3 }}</h5>
                                            </div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2"><button class="btn btn-danger btn-floating" @click.prevent="modifTarifSemaine3()"><i class="fas fa-magic"></i></button></h5>
                                            </div>
                                        </div>
                                        <!-- Information sur la quatrième saisie en partant de la fin -->
                                        <div class="row pt-2">
                                            <div class="col-2"></div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2">{{ allTarif[3].semaine_tarif }}</h5>
                                            </div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2">{{ semaine4 }}</h5>
                                            </div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2"><button class="btn btn-danger btn-floating" @click.prevent="modifTarifSemaine4()"><i class="fas fa-magic"></i></button></h5>
                                            </div>
                                        </div>
                                        <!-- Information sur la cinquième saisie en partant de la fin -->
                                        <div class="row pt-2">
                                            <div class="col-2"></div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2">{{ allTarif[4].semaine_tarif }}</h5>
                                            </div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2">{{ semaine5 }}</h5>
                                            </div>
                                            <div class="col-3 border border-dark rounded mr-1">
                                                <h5 class="text-center mt-2"><button class="btn btn-danger btn-floating" @click.prevent="modifTarifSemaine5()"><i class="fas fa-magic"></i></button></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        


                        
                    </div>


                </div>
            
        </div>
        
    </div>
  
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import store from '../store/index'
import Swal from 'sweetalert2'


export default {
  name: 'ListeTarif',

  data () {
    return {
      semaineSaisie: "",
      semaine1: "",
      semaine2: "",
      semaine3: "",
      semaine4: "",
      semaine5: "",
      semaine_tarif: '',
      prix_entree: '',
      prix_plat: '',
      prix_fromage: '',
      prix_dessert: '',
      prix_pain: '',
      erreur: false,
      visible: false,
      allTarif: [],
      tarifId1: '',
    }
  },

  methods: {
      retour(){
      this.$router.push('/connecter')
    },

    modifTarifSemaine1(){
        this.$router.push('/ModificationTarif1')
    },
    modifTarifSemaine2(){
        this.$router.push('/ModificationTarif2')
    },
    modifTarifSemaine3(){
        this.$router.push('/ModificationTarif3')
    },
    modifTarifSemaine4(){
        this.$router.push('/ModificationTarif4')
    },
    modifTarifSemaine5(){
        this.$router.push('/ModificationTarif5')
    },

    afficheListeTarif(){
        this.visible = true
        axios.get(`http://localhost:3000/tarif`, {
        
        },
        {headers: {Authorization: `Bearer ${store.state.token}`},}
        )
        .then(response => {
            this.semaine1 = moment(String(new Date(this.allTarif[0].createdAt))).format('DD-MM-YYYY')
            this.semaine2 = moment(String(new Date(this.allTarif[1].createdAt))).format('DD-MM-YYYY')
            this.semaine3 = moment(String(new Date(this.allTarif[2].createdAt))).format('DD-MM-YYYY')
            this.semaine4 = moment(String(new Date(this.allTarif[3].createdAt))).format('DD-MM-YYYY')
            this.semaine5 = moment(String(new Date(this.allTarif[4].createdAt))).format('DD-MM-YYYY')
            this.tarifId1 = this.allTarif[0].tarifId
            
            for(const tarif of response.data.tarif){
            this.allTarif.push(tarif)
            }
            this.allTarif.forEach(tarif => {
            this.profil.push(tarif.tarifId)
            })
            
            
        })
        .catch(error => {console.log('An error occurred:', error.response);})
    },
    creationTarif(){
        if(this.semaineSaisie === null || this.semaineSaisie === "") {
            this.erreur = true
        } else {
            axios.post('http://localhost:3000/tarif', {
            semaine_tarif: this.semaineSaisie,
            prix_entree: this.prix_entree,
            prix_plat: this.prix_plat,
            prix_fromage: this.prix_fromage,
            prix_dessert: this.prix_dessert,
            prix_pain: this.prix_pain
                
            },
            { headers: { Authorization: `Bearer ${store.state.token}` },}
        )
        .then(() => {
            Swal.fire({
            icon: 'success',
            title: "Les tarifs sont publiés",
            showConfirmButton: false,
            timer: 2000
            })
            
        })
        .catch(error => {console.log("Tarifs non publiées : ", error.response);
            Swal.fire({
            title: "Une erreur est survenue",
            icon: "error",
            timer: 2500,
            showConfirmButton: false,
            timerProgressBar: true
            }) 
        })
            setTimeout(console.log('validation des tarifs'), 50000)
            window.location.reload()
        }
        
        
      
    },

  },
  created() {
      this.allTarif = []
  },
  mounted() {
    //récupération des Tarifs
    axios.get(`http://localhost:3000/tarif`, {
        
        },
        {headers: {Authorization: `Bearer ${store.state.token}`},}
        )
        .then(response => {
            console.log(response.data.tarif);
            for(const tarif of response.data.tarif){
            this.allTarif.push(tarif)
            }
            this.allTarif.forEach(tarif => {
            this.profil.push(tarif.tarifId)
            })     
        })
        .catch(error => {console.log('An error occurred:', error.response);})
        
    },

  
  
}
</script>

<style scoped>
.container {
  height: 86vh;
}
.card {
  opacity: 0.95;
}
.misePage {
  margin: 95px auto 0px auto;
}
.semaine {
    color: blue;
    font-weight: bold;
}
.erreur {
    color: red;
    font-weight: bold;
    font-size: 25px;
}
.menu {
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 15px;

}
</style>

