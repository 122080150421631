<template>
  <div class="container misePage pt-5 pb-5">
    <div class="card ">
      <div class="card-header text-center">
        <button @click="retour()" class="btn btn-success home mt-2" >Retour Accueil</button>
        <h1>Création d'un utilisateur pour la cantine</h1>
      </div>

      <!-- /// Formulaire pour la création d'un utilisateur \\\ -->
      <form action="" class="card-body mt-3 w-75 ml-auto mr-auto">
        <div class="form-group">
          <label class="ml-2" for="identifiant">Entrez votre identifiant</label>
          <input v-model="identifiant" type="text" id="identifiant" class="form-control">
        </div>
        <div class="form-group">
          <label class="ml-2" for="nom">Entrez votre nom</label>
          <input v-model="nom" type="text" id="nom" class="form-control">
        </div>
        <div class="form-group">
          <label class="ml-2" for="prenom">Entrez votre prénom</label>
          <input v-model="prenom" type="text" id="prenom" class="form-control">
        </div>
        <div class="form-group">
          <label class="ml-2" for="password">Entrez votre mot de passe</label>
          <input v-model="password" type="password" id="password" class="form-control">
        </div>
        <div class="form-group text-center">
          <button class="btn btn-primary" @click.prevent="register()">S'incrire</button>
        </div>
      </form>
    </div>
       

  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'SignUp',
  data () {
    return{
      password: '',
      identifiant: '',
      nom: '',
      prenom: ''
    }
  },
  methods: {
    retour(){
      this.$router.push('/connecter')
    },
    register() {
      console.log(this);
      axios.post('http://localhost:3000/signup', {
        password: this.password,
        identifiant: this.identifiant,
        nom: this.nom,
        prenom: this.prenom,
        })
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Utilisateur créé',
            showConfirmButton: false,
            timer: 1500
          }),
          this.$router.push('/signup'),
          window.location.reload()
      }) 
        .catch(error => {
        Swal.fire({
          icon: 'error',
            title: "Identifiant déja connu dans la base de données",
            showConfirmButton: false,
            timer: 1500
          })
          console.log('Impossible de créer votre compte front : ', error);
      })
    }
  },
}
</script>

<style scoped>
.container {
  height: 88vh;
}
.card {
  opacity: 0.9;
}
.home {
  display: block;
}
.misePage {
  margin: 95px auto 0px auto;
}
</style>