<template>
  <div class="container misePage pt-5 pb-5" v-if="$store.state.isModerateur"  >
    <div class="card">
      <div class="card-header text-center">
        <h1>Modification d'un utilisateur pour la cantine</h1>
      </div>
      <div class="card-deck ml-3 mr-3 mb-3">
        <form action="" class="card-body mt-3 w-75 ml-auto mr-auto">
            <div class="form-group">
              <label class="ml-2" for="identifiant">Identifiant de connexion</label>
              <input v-model="identifiant" type="text" id="identifiant" class="form-control" disabled="disabled">
            </div>
            <div class="form-group">
              <label class="ml-2" for="nom">Modifier le nom</label>
              <input v-model="nom" type="text" id="nom" class="form-control">
            </div>
            <div class="form-group">
              <label class="ml-2" for="prenom">Modifier le prénom</label>
              <input v-model="prenom" type="text" id="prenom" class="form-control">
            </div>
            <div class="form-group">
              <label class="ml-2" for="actif">Utilisateur actif </label>
              <input type="checkbox" id="checkbox" class="ml-2" v-model="actif">
            </div>
            <div class="form-group text-center">
              <button class="btn btn-warning mr-5" @click.prevent="updateProfil()">Modifier</button>
              <button v-if="$store.state.isModerateur" type="button" class="btn btn-danger sup" @click="deleteProfil()"><i class="far fa-trash-alt"></i>  Suppression utilisateur</button>
              <button @click="retour()" class="btn btn-success home ml-5" >Retour arrière</button>
            </div>
          </form>

      </div>
    </div>
   
  </div>
</template>

<script>
import axios from 'axios'
import store from '../store/index'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'Profil',
  data () {
    return{
      id: this.$route.params.id,
      user: [],
      userModif: '',
      password: '',
      profil: true,
      passe: false,
      delet: false,
      law: false,
      avatar:'',
      avatarTest: true,
      changeBio: '',
      changePassword:'',
      nom: '',
      prenom: '',
      identifiant: '',
      actif: false,
      newAvatar: '/images/avatar-default.png',
      file: null,
    }
  },
  methods: {
    retour(){
      this.$router.push('/usersAll')
      
    },
    // méthodes pour le changement des informations utilisateurs
    updateProfil() {
        axios.put(`http://localhost:3000/profil`, {
          nom: this.nom,
          prenom: this.prenom,
          actif: this.actif,
          userModif: this.id,
          userId: this.id},
      )
      .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Profil mis à jour',
            showConfirmButton: false,
            timer: 1500
          })
          this.$router.push(`/profil/${this.id}`)
          window.location.reload()
      }) 
      .catch(error => {
        Swal.fire({
          icon: 'error',
            title: "Le profil n'a pas pu être mis à jour, veuillez réessayer !",
            showConfirmButton: false,
            timer: 1500
          })
          console.log('An error occurred:', error.response);
      })
    },

  

    // Méthodes pour la suppression du compte User
    deleteProfil() {
      // axios.delete(`http://localhost:3000/profil/${this.id}`, {
      //         nom: this.nom,
      //     prenom: this.prenom,
      //     actif: this.actif,
      //     userModif: this.id,
      //     userId: this.id},
      //       )



      Swal.fire({
        title: 'Êtes-vous sûr ?',
          text: "Vous me pourrez pas revenir en arrière !",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, supprimer le compte!'
      })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Compte supprimé!',
              icon: 'success',
              timer: 1500
            })
            axios.delete(`http://localhost:3000/profil/${this.id}`, {
              userId: this.id},
            )
            //this.$store.dispatch('setToken', null)
            // this.$store.dispatch('setUser', null)
            // this.$store.dispatch('setModerateur', false)
            this.$router.push('/')
          }
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: "Le compte n'a pas pu être supprimé, veuillez réessayer !",
            showConfirmButton: false,
            timer: 1500
          })
          console.log('An error occurred:', error.response);
        })
    },



    
  },

  mounted() {
   axios.get(`http://localhost:3000/profil/${this.id}`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
      .then(profil => {
        this.user = profil.data
        this.nom = profil.data.nom
        this.prenom = profil.data.prenom
        this.actif = profil.data.actif
        this.identifiant = profil.data.identifiant
      })   
      .catch(error => {
        console.log('An error occurred:', error.response);
      })
  },
 
  computed: {
      ...mapState(['isModerateur', 'userId'])
  },
}



</script>

<style scoped>


.container {
  height: 89vh;
}
.card {
  opacity: 0.9;
}
.form-group, .btn {
  font-size: 1vw;
  font-weight: bold;
}
.misePage {
  margin: 95px auto 0px auto;
}

</style>