<template>
  <div class="container misePage pt-5 mb-4" v-if="$store.state.isModerateur">
    <div class="container " >
      <button @click="retour()" class="btn btn-success home mr-5 mb-2" >Retour Accueil</button>
      <input type="text" v-model="search" placeholder="Recherche par nom" class="recherche">
      <div class="row">
        <div class="col mt-2">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-3">
                  <p>Utilisateur: {{ this.allUsers }}</p>
                </div>
                <div class="col-3">
                  <p>Nom: {{ this.allRecap }}</p>
                </div>
                <div class="col-3">
                  <p>Prénom:</p>
                </div>
                <div class="col-3">
                  <select v-model="semaineSaisie" class="form-select ml-5" aria-label="Default select example">
                    <option disabled value="">Semaine</option>
                    <option>{{ semaine1 }}</option>
                    <option>{{ semaine2 }}</option>
                    <option>{{ semaine3 }}</option>
                    <option>{{ semaine4 }}</option>
                  </select>
                  <button @click="listeSaisie()">controle</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="row"  v-bind:key="index" v-for="(user, index) in filteredList">
          <div class="col mt-2" >
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-3">
                    <p class="pGras">{{ user.identifiant }}</p>
                  </div>
                  <div class="col-3">
                    <p class="pGras">{{ user.nom }}</p>
                  </div>
                  <div class="col-3">
                    <p class="pGras">{{ user.prenom }}</p>
                  </div>
                  <div class="col-2">
                    <!-- <p>{{ allRecap.saisie }}</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import store from '../store/index'
import moment from 'moment'


export default {
  name: 'UtilisateurSaisie',
  data () {
    return{
      id: this.$route.params.id,
      user: '',
      allUsers: [],
      search: '',
      semaineSaisie: '',
      allRecap: [],
      allUsersSaisie: [],
      semaine1: moment(String(new Date())).format('W-YYYY'),
      semaine2: moment(String(new Date())).add(7, 'days').format('W-YYYY'),
      semaine3: moment(String(new Date())).add(14, 'days').format('W-YYYY'),
      semaine4: moment(String(new Date())).add(21, 'days').format('W-YYYY'),
    }
  },
    methods: {
    retour(){
      this.$router.push('/connecter')
    },

    listeSaisie(){
      axios.get(`http://localhost:3000/listeUsersCommande/${this.semaineSaisie}`, {
          
            headers: {Authorization: `Bearer ${store.state.token}`}}
            )

          .then(response => {
            console.log("les utilisateurs sont :" + this.allUsers );
            console.log("AllRecap contient " + response.data.listecde);
            for(const recap of response.data.listecde) {
              this.allRecap.push(recap, {saisie : true})
            }

          })
          setTimeout(this.controleSaisie, 600)
    },

    controleSaisie() {
      console.log("entrée dans le controle saisie");
      // for (let i in this.allUsers){
      //   if (this.allUsers[i].userId === this.allRecap.id_utilisateur) {
          
      //     this.allUsers[i].saisie = true
      //   } else {
          
      //     this.allUsers[i].saisie = false
      //   }
        
      // } 
      var _ = require('lodash');

      var Obj3 = _.differenceWith(this.allUsers, this.allRecap, function (o1, o2) {
          return o1['userId'] === o2['id_utilisateur']
      });
      console.log(Obj3);
      console.log("passage dans le controle");
    },

  },



 mounted() {
  //récupération des utilisateurs
  axios.get(`http://localhost:3000/listeUsers`, {
    headers: {Authorization: `Bearer ${store.state.token}`}}
    )
      .then(response => {
        console.log("mounted users" + response.data.users);
        for(const utilisateur of response.data.users){
        this.allUsers.push(utilisateur, {saisie : false})
        }
        this.allUsers.forEach(utilisateur => {
        this.profil.push(utilisateur.userId)
        
        
        })  
          console.log("les users sont  "  + this.allUsers);
      })
      
    .catch(error => {console.log('An error occurred:', error.response);})
  },


  computed: {
    // filtre pour la recherche des utilisateurs
    filteredList(){
      if(this.search){
      return this.allUsers.filter((item)=>{
        return this.search.toLowerCase().split(' ').every(v => item.nom.toLowerCase().includes(v))
      })
      }else{
        return this.allUsers;
      }
    }
  }

 }


</script>

<style scoped>

.misePage {
  margin: 95px auto 0px auto;
  height: 110vh;
}
p {
  font-size: 1vw;
}
.pGras {
  font-weight: bold;
}
.recherche {
  width: 500px;
  margin-left: 150px;

}

</style>