import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  plugins: [
    createPersistedState()
  ],
  state: {
    token: null,
    userId: null,
    isUserLoggedIn: false,
    isModerateur: false,
    nom: null,
    prenom: null
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      state.isUserLoggedIn = !!(token)
    },
    setUser (state, user) {
      state.userId = user
    },
    setNom (state, nomlogin) {
      state.nom = nomlogin
    },
    setPrenom (state, prenomlogin) {
      state.prenom = prenomlogin
    },
    setModerateur (state, moderateur) {
      state.isModerateur = moderateur
    }
  },
  actions: {
    setToken ({commit}, token) {
      commit('setToken', token)
    },
    setUser ({commit}, user) {
      commit('setUser', user)
    },
    setNom ({commit}, nomlogin) {
      commit('setNom', nomlogin)
    },
    setPrenom ({commit}, prenomlogin) {
      commit('setPrenom', prenomlogin)
    },
    setModerateur ({commit}, moderateur) {
      commit('setModerateur', moderateur)
    }
  }
})
