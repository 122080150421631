<template>
  <div class="container-fluid pt-5 pb-5 mt-5 mb-4">
    <div class="card">
      <div class="card-header text-center">
        <h1>Modification du menu de la semaine : {{ semaineSaisie }}</h1>
        
        <h4 v-if="visible">Le montant Global de la commande est de :  {{ montantCde }} €</h4>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-6">
            <h5 class="text-danger" v-if="ErreurLundiTrop">Il y a trop de garniture en commande pour le lundi</h5>
            <h5 class="text-danger" v-if="ErreurLundiManque">Il n'y a pas assez de garniture en commande pour le lundi</h5>
            <h5 class="text-danger" v-if="ErreurMardiTrop">Il y a trop de garniture en commande pour le Mardi</h5>
            <h5 class="text-danger" v-if="ErreurMardiManque">Il n'y a pas assez de garniture en commande pour le Mardi</h5>
            <h5 class="text-danger" v-if="ErreurMercrediTrop">Il y a trop de garniture en commande pour le Mercredi</h5>
            <h5 class="text-danger" v-if="ErreurMercrediManque">Il n'y a pas assez de garniture en commande pour le Mercredi</h5>
            <h5 class="text-danger" v-if="ErreurJeudiTrop">Il y a trop de garniture en commande pour le Jeudi</h5>
            <h5 class="text-danger" v-if="ErreurJeudiManque">Il n'y a pas assez de garniture en commande pour le Jeudi</h5>
          </div>
          <div class="col-2">
            <button v-if="ErreurLundiTrop || ErreurLundiManque || ErreurMardiTrop || ErreurMardiManque || ErreurMercrediTrop || ErreurMercrediManque ||ErreurJeudiTrop || ErreurJeudiManque" @click="EffaceMessage()" class="btn btn-warning float-right" >Modifier les garnitures</button>
          </div>

        </div>
        
        <button @click="retour()" class="btn btn-success float-right" >Accueil</button>

    


      </div>
      <div>
        
        <div v-if="!visible" class = "row ml-5 mr-5 mb-5 mt-2">
          <h2 class="pt-5 ml-auto mr-auto">Chargement de la commande pour modifier le menu de la semaine {{ semaineSaisie }}</h2>
          
        </div> 




        <div class = "row ml-5 mr-5 mb-5 mt-2">
          <div  v-if="visible" class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Lundi  {{ jour1 }}</h2>
            </div>
            <!-- début lundi saisie-->
            <div class = "col border text-center mt-2">

              <!-- Entrées début -->
              <div class="row">
                <div class="col-7">
                  <h3 class="mt-2">Entrées</h3>
                </div>
                <div class="col-2 d-flex align-items-center ">
                  <h4 class="mr-2">C</h4>
                  <h4 class="taille ml-2">🐷</h4>
                </div>
                <div class="col-3">
                  <h4 class="mt-2">Qté</h4>
                </div>
              </div>
           
              <!-- 1° choix entrées -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat1[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat1[1].complet == false || cle_plat1[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat1[1].complet == true || cle_plat1[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat1[1].porc == false || cle_plat1[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat1[1].porc == true || cle_plat1[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat1[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat1">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>

              <!-- 2° choix entrées -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat2[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat2[1].complet == false || cle_plat2[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat2[1].complet == true || cle_plat2[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat2[1].porc == false || cle_plat2[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat2[1].porc == true || cle_plat2[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat2[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat2">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat3[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat3[1].complet == false || cle_plat3[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat3[1].complet == true || cle_plat3[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat3[1].porc == false || cle_plat3[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat3[1].porc == true || cle_plat3[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat3[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat3">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat4[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat4[1].complet == false || cle_plat4[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat4[1].complet == true || cle_plat4[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat4[1].porc == false || cle_plat4[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat4[1].porc == true || cle_plat4[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat4[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat4">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2 mr-5">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat5[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat5[1].complet == false || cle_plat5[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat5[1].complet == true || cle_plat5[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat5[1].porc == false || cle_plat5[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat5[1].porc == true || cle_plat5[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat5[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat5">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat6[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat6[1].complet == false || cle_plat6[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat6[1].complet == true || cle_plat6[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat6[1].porc == false || cle_plat6[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat6[1].porc == true || cle_plat6[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat6[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat6">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat7[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat7[1].complet == false || cle_plat7[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat7[1].complet == true || cle_plat7[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat7[1].porc == false || cle_plat7[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat7[1].porc == true || cle_plat7[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat7[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat7">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat8[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat8[1].complet == false || cle_plat8[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat8[1].complet == true || cle_plat8[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat8[1].porc == false || cle_plat8[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat8[1].porc == true || cle_plat8[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat8[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat8">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat9[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat9[1].complet == false || cle_plat9[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat9[1].complet == true || cle_plat9[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat9[1].porc == false || cle_plat9[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat9[1].porc == true || cle_plat9[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat9[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat9">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat10[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat10[1].complet == false || cle_plat10[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat10[1].complet == true || cle_plat10[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat10[1].porc == false || cle_plat10[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat10[1].porc == true || cle_plat10[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat10[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat10">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat11[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat11[1].complet == false || cle_plat11[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat11[1].complet == true || cle_plat11[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat11[1].porc == false || cle_plat11[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat11[1].porc == true || cle_plat11[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat11[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat11">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat12[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat12[1].complet == false || cle_plat12[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat12[1].complet == true || cle_plat12[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat12[1].porc == false || cle_plat12[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat12[1].porc == true || cle_plat12[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat12[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat12">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat13[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat13[1].complet == false || cle_plat13[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat13[1].complet == true || cle_plat13[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat13[1].porc == false || cle_plat13[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat13[1].porc == true || cle_plat13[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat13[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat13">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              
              <!-- Fromage fin -->

              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat14[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat14[1].complet == false || cle_plat14[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat14[1].complet == true || cle_plat14[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat14[1].porc == false || cle_plat14[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat14[1].porc == true || cle_plat14[4] == true" class="mr-3" for="complet"></label>
                </div>
                <div v-if="cle_plat14[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat14">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat15[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat15[1].complet == false || cle_plat13[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat15[1].complet == true || cle_plat13[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat15[1].porc == false || cle_plat15[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat15[1].porc == true || cle_plat15[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat15[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat15">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat16[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat16[1].complet == false || cle_plat16[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat16[1].complet == true || cle_plat16[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat16[1].porc == false || cle_plat16[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat16[1].porc == true || cle_plat16[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat16[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat16">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row pt-2 pb-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat17[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat17[1].complet == false || cle_plat17[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat17[1].complet == true || cle_plat17[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat17[1].porc == false || cle_plat17[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat17[1].porc == true || cle_plat17[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat17[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat17">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Dessert fin -->
              <div>
                <button @click="supSaisieLundi(),CalculMontantCommande()" class="btn btn-danger float-right mt-2 mb-2">🗑️</button>
              </div>

              

            </div>
            <!-- Fin lundi saisie-->
          </div>
          <div v-if="visible" class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Mardi {{ jour2}}</h2>
            </div>
            <!-- début lundi saisie-->
            <div class = "col border text-center mt-2">

              <!-- Entrées début -->
              <div class="row">
                <div class="col-7">
                  <h3 class="mt-2">Entrées</h3>
                </div>
                <div class="col-2 d-flex align-items-center ">
                  <h4 class="mr-2">C</h4>
                  <h4 class="taille ml-2">🐷</h4>
                </div>
                <div class="col-3">
                  <h4 class="mt-2">Qté</h4>
                </div>
              </div>
           
              <!-- 1° choix entrées -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat18[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat18[1].complet == false || cle_plat18[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat18[1].complet == true || cle_plat18[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat18[1].porc == false || cle_plat18[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat18[1].porc == true || cle_plat18[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat18[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat18">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix entrées -->
              <div class="row pt-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat19[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat19[1].complet == false || cle_plat19[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat19[1].complet == true || cle_plat19[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat19[1].porc == false || cle_plat19[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat19[1].porc == true || cle_plat19[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat19[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat19">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat20[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat20[1].complet == false || cle_plat20[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat20[1].complet == true || cle_plat20[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat20[1].porc == false || cle_plat20[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat20[1].porc == true || cle_plat20[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat20[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat20">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat21[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat21[1].complet == false || cle_plat21[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat21[1].complet == true || cle_plat21[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat21[1].porc == false || cle_plat21[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat21[1].porc == true || cle_plat21[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat21[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat21">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat22[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat22[1].complet == false || cle_plat22[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat22[1].complet == true || cle_plat22[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat22[1].porc == false || cle_plat22[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat22[1].porc == true || cle_plat22[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat22[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat22">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat23[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat23[1].complet == false || cle_plat23[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat23[1].complet == true || cle_plat23[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat23[1].porc == false || cle_plat23[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat23[1].porc == true || cle_plat23[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat23[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat23">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat24[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat24[1].complet == false || cle_plat24[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat24[1].complet == true || cle_plat24[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat24[1].porc == false || cle_plat24[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat24[1].porc == true || cle_plat24[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat24[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat24">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat25[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat25[1].complet == false || cle_plat25[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat25[1].complet == true || cle_plat25[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat25[1].porc == false || cle_plat25[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat25[1].porc == true || cle_plat25[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat25[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat25">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat26[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat26[1].complet == false || cle_plat26[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat26[1].complet == true || cle_plat26[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat26[1].porc == false || cle_plat26[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat26[1].porc == true || cle_plat26[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat26[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat26">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat27[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat27[1].complet == false || cle_plat27[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat27[1].complet == true || cle_plat27[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat27[1].porc == false || cle_plat27[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat27[1].porc == true || cle_plat27[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat27[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat27">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat28[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat28[1].complet == false || cle_plat28[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat28[1].complet == true || cle_plat28[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat28[1].porc == false || cle_plat28[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat28[1].porc == true || cle_plat28[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat28[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat28">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat29[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat29[1].complet == false || cle_plat29[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat29[1].complet == true || cle_plat29[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat29[1].porc == false || cle_plat29[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat29[1].porc == true || cle_plat29[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat29[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat29">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat30[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat30[1].complet == false || cle_plat30[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat30[1].complet == true || cle_plat30[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat30[1].porc == false || cle_plat30[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat30[1].porc == true || cle_plat30[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat30[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat30">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Fromage fin -->

              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat31[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat31[1].complet == false || cle_plat31[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat31[1].complet == true || cle_plat31[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat31[1].porc == false || cle_plat31[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat31[1].porc == true || cle_plat31[4] == true" class="mr-3" for="complet"></label>
                </div>
                <div v-if="cle_plat31[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat31">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Fin Pain -->

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat32[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat32[1].complet == false || cle_plat32[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat32[1].complet == true || cle_plat32[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat32[1].porc == false || cle_plat32[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat32[1].porc == true || cle_plat32[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat32[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat32">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat33[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat33[1].complet == false || cle_plat33[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat33[1].complet == true || cle_plat33[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat33[1].porc == false || cle_plat33[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat33[1].porc == true || cle_plat33[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat33[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat33">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row pt-2 pb-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat34[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat34[1].complet == false || cle_plat34[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat34[1].complet == true || cle_plat34[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat34[1].porc == false || cle_plat34[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat34[1].porc == true || cle_plat34[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat34[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat34">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Dessert fin -->
              <div>
                <button @click="supSaisieMardi(),CalculMontantCommande()" class="btn btn-danger float-right mt-2 mb-2">🗑️</button>
              </div>

              

            </div>
            <!-- Fin Mardi saisie-->

          </div>
          <div v-if="visible" class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Mercredi {{ jour3}}</h2>
            </div>
            <!-- début lundi saisie-->
            <div class = "col border text-center mt-2">

              <!-- Entrées début -->
              <div class="row">
                <div class="col-7">
                  <h3 class="mt-2">Entrées</h3>
                </div>
                <div class="col-2 d-flex align-items-center ">
                  <h4 class="mr-2">C</h4>
                  <h4 class="taille ml-2">🐷</h4>
                </div>
                <div class="col-3">
                  <h4 class="mt-2">Qté</h4>
                </div>
              </div>
           
              <!-- 1° choix entrées -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat35[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat35[1].complet == false || cle_plat35[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat35[1].complet == true || cle_plat35[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat35[1].porc == false || cle_plat35[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat35[1].porc == true || cle_plat35[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat35[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat35">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix entrées -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat36[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat36[1].complet == false || cle_plat36[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat36[1].complet == true || cle_plat36[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat36[1].porc == false || cle_plat36[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat36[1].porc == true || cle_plat36[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat36[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat36">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat37[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat37[1].complet == false || cle_plat37[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat37[1].complet == true || cle_plat37[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat37[1].porc == false || cle_plat37[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat37[1].porc == true || cle_plat37[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat37[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat37">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat38[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat38[1].complet == false || cle_plat38[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat38[1].complet == true || cle_plat38[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat38[1].porc == false || cle_plat38[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat38[1].porc == true || cle_plat38[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat38[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat38">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat39[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat39[1].complet == false || cle_plat39[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat39[1].complet == true || cle_plat39[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat39[1].porc == false || cle_plat39[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat39[1].porc == true || cle_plat39[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat39[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat39">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat40[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat40[1].complet == false || cle_plat40[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat40[1].complet == true || cle_plat40[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat40[1].porc == false || cle_plat40[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat40[1].porc == true || cle_plat40[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat40[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat40">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat41[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat41[1].complet == false || cle_plat41[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat41[1].complet == true || cle_plat41[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat41[1].porc == false || cle_plat41[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat41[1].porc == true || cle_plat41[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat41[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat41">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat42[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat42[1].complet == false || cle_plat42[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat42[1].complet == true || cle_plat42[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat42[1].porc == false || cle_plat42[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat42[1].porc == true || cle_plat42[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat42[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat42">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat43[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat43[1].complet == false || cle_plat43[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat43[1].complet == true || cle_plat43[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat43[1].porc == false || cle_plat43[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat43[1].porc == true || cle_plat43[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat43[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat43">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat44[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat44[1].complet == false || cle_plat44[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat44[1].complet == true || cle_plat44[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat44[1].porc == false || cle_plat44[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat44[1].porc == true || cle_plat44[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat44[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat44">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat45[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat45[1].complet == false || cle_plat45[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat45[1].complet == true || cle_plat45[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat45[1].porc == false || cle_plat45[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat45[1].porc == true || cle_plat45[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat45[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat45">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat46[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat46[1].complet == false || cle_plat46[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat46[1].complet == true || cle_plat46[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat46[1].porc == false || cle_plat46[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat46[1].porc == true || cle_plat46[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat46[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat46">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat47[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat47[1].complet == false || cle_plat47[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat47[1].complet == true || cle_plat47[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat47[1].porc == false || cle_plat47[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat47[1].porc == true || cle_plat47[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat47[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat47">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Fromage fin -->

              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat48[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat48[1].complet == false || cle_plat48[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat48[1].complet == true || cle_plat48[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat48[1].porc == false || cle_plat48[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat48[1].porc == true || cle_plat48[4] == true" class="mr-3" for="complet"></label>
                </div>
                <div v-if="cle_plat48[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat48">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Fin Pain -->

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat49[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat49[1].complet == false || cle_plat49[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat49[1].complet == true || cle_plat49[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat49[1].porc == false || cle_plat49[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat49[1].porc == true || cle_plat49[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat49[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat49">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat50[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat50[1].complet == false || cle_plat50[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat50[1].complet == true || cle_plat50[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat50[1].porc == false || cle_plat50[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat50[1].porc == true || cle_plat50[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat50[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat50">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row pt-2 pb-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat51[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat51[1].complet == false || cle_plat51[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat51[1].complet == true || cle_plat51[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat51[1].porc == false || cle_plat51[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat51[1].porc == true || cle_plat51[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat51[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat51">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Dessert fin -->
              <div>
                <button @click="supSaisieMercredi(),CalculMontantCommande()" class="btn btn-danger float-right mt-2 mb-2">🗑️</button>
              </div>

              

            </div>
            <!-- Fin Mercredi saisie-->

          </div>
          <div v-if="visible" class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Jeudi {{ jour4 }}</h2>
            </div>
            <!-- début lundi saisie-->
            <div class = "col border text-center mt-2">

              <!-- Entrées début -->
              <div class="row">
                <div class="col-7">
                  <h3 class="mt-2">Entrées</h3>
                </div>
                <div class="col-2 d-flex align-items-center ">
                  <h4 class="mr-2">C</h4>
                  <h4 class="taille ml-2">🐷</h4>
                </div>
                <div class="col-3">
                  <h4 class="mt-2">Qté</h4>
                </div>
              </div>
           
              <!-- 1° choix entrées -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat52[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat52[1].complet == false || cle_plat52[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat52[1].complet == true || cle_plat52[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat52[1].porc == false || cle_plat52[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat52[1].porc == true || cle_plat52[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat52[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat52">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix entrées -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat53[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat53[1].complet == false || cle_plat53[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat53[1].complet == true || cle_plat53[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat53[1].porc == false || cle_plat53[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat53[1].porc == true || cle_plat53[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat53[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat53">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat54[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat54[1].complet == false || cle_plat54[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat54[1].complet == true || cle_plat54[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat54[1].porc == false || cle_plat54[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat54[1].porc == true || cle_plat54[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat54[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat54">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat55[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat55[1].complet == false || cle_plat55[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat55[1].complet == true || cle_plat55[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat55[1].porc == false || cle_plat55[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat55[1].porc == true || cle_plat55[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat55[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat55">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat56[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat56[1].complet == false || cle_plat56[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat56[1].complet == true || cle_plat56[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat56[1].porc == false || cle_plat56[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat56[1].porc == true || cle_plat56[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat56[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat56">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat57[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat57[1].complet == false || cle_plat57[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat57[1].complet == true || cle_plat57[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat57[1].porc == false || cle_plat57[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat57[1].porc == true || cle_plat57[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat57[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat57">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat58[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat58[1].complet == false || cle_plat58[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat58[1].complet == true || cle_plat58[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat58[1].porc == false || cle_plat58[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat58[1].porc == true || cle_plat58[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat58[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat58">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat59[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat59[1].complet == false || cle_plat59[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat59[1].complet == true || cle_plat59[3] == true" class="mr-3" for="complet">✔️</label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat59[1].porc == false || cle_plat59[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat59[1].porc == true || cle_plat59[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat59[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat59">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat60[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat60[1].complet == false || cle_plat60[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat60[1].complet == true || cle_plat60[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat60[1].porc == false || cle_plat60[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat60[1].porc == true || cle_plat60[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat60[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat60">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat61[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat61[1].complet == false || cle_plat61[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat61[1].complet == true || cle_plat61[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat61[1].porc == false || cle_plat61[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat61[1].porc == true || cle_plat61[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat61[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat61">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat62[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat62[1].complet == false || cle_plat62[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat62[1].complet == true || cle_plat62[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat62[1].porc == false || cle_plat62[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat62[1].porc == true || cle_plat62[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat62[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat62">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat63[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat63[1].complet == false || cle_plat63[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat63[1].complet == true || cle_plat63[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat63[1].porc == false || cle_plat63[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat63[1].porc == true || cle_plat63[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat63[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat63">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat64[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat64[1].complet == false || cle_plat64[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat64[1].complet == true || cle_plat64[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat64[1].porc == false || cle_plat64[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat64[1].porc == true || cle_plat64[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat64[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat64">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Fromage fin -->


              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat65[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat65[1].complet == false || cle_plat65[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat65[1].complet == true || cle_plat65[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat65[1].porc == false || cle_plat65[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat65[1].porc == true || cle_plat65[4] == true" class="mr-3" for="complet"></label>
                </div>
                <div v-if="cle_plat65[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat65">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Fin Pain -->

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat66[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat66[1].complet == false || cle_plat66[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat66[1].complet == true || cle_plat66[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat66[1].porc == false || cle_plat66[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat66[1].porc == true || cle_plat66[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat66[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat66">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row pt-2 ">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat67[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat67[1].complet == false || cle_plat67[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat67[1].complet == true || cle_plat67[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat67[1].porc == false || cle_plat67[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat67[1].porc == true || cle_plat67[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat67[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat67">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row pt-2 pb-2">
                <div class="col-7 border border-dark rounded">
                  <h5 class="text-left mt-1">{{ cle_plat68[1] }}</h5>
                </div>
                <div class="col-1">
                  <label v-if="cle_plat68[1].complet == false || cle_plat68[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat68[1].complet == true || cle_plat68[3] == true" class="mr-3" for="complet"></label>
                </div>  
                <div class="col-1">
                  <label v-if="cle_plat68[1].porc == false || cle_plat68[4] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat68[1].porc == true || cle_plat68[4] == true" class="mr-3" for="complet">✔️</label>
                </div>
                <div v-if="cle_plat68[1] == 'vide'" class="col-2 border border-dark rounded ml-3"></div>
                <div v-else class="col-3">
                  <select v-on:click="CalculMontantCommande" class="custom-select" v-model="qt_plat68">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <!-- Dessert fin -->
              <div>
                
                  

                <button @click="supSaisieJeudi(),CalculMontantCommande()" class="btn btn-danger float-right mt-2 mb-2">🗑️</button>
              </div>
              

              

            </div>
            <!-- Fin Jeudi saisie-->

          </div>



        </div>




        <div>



          <!-- Ajout de la fenêtre modale en bootstrap -->


        <div v-if="visible" class="row">
          <div class="col-1"></div>
          <div class="col-4">
            <h5 class="text-danger" v-if="ErreurLundiTrop">Il y a trop de garniture en commande pour le lundi</h5>
            <h5 class="text-danger" v-if="ErreurLundiManque">Il n'y a pas assez de garniture en commande pour le lundi</h5>
            <h5 class="text-danger" v-if="ErreurMardiTrop">Il y a trop de garniture en commande pour le Mardi</h5>
            <h5 class="text-danger" v-if="ErreurMardiManque">Il n'y a pas assez de garniture en commande pour le Mardi</h5>
            <h5 class="text-danger" v-if="ErreurMercrediTrop">Il y a trop de garniture en commande pour le Mercredi</h5>
            <h5 class="text-danger" v-if="ErreurMercrediManque">Il n'y a pas assez de garniture en commande pour le Mercredi</h5>
            <h5 class="text-danger" v-if="ErreurJeudiTrop">Il y a trop de garniture en commande pour le Jeudi</h5>
            <h5 class="text-danger" v-if="ErreurJeudiManque">Il n'y a pas assez de garniture en commande pour le Jeudi</h5>
          </div>
          <div class="col-2">
              <button v-if="ErreurLundiTrop || ErreurLundiManque || ErreurMardiTrop || ErreurMardiManque || ErreurMercrediTrop || ErreurMercrediManque ||ErreurJeudiTrop || ErreurJeudiManque" @click="EffaceMessage()" class="btn btn-warning float-right" >Modifier les garnitures</button>
            </div>
          <!-- Affichage du montant de la commande -->
          <div class="col-2 border border-dark rounded mb-3">
            <h5 class="text-left mt-1">Montant global de la commande est de : {{ montantCde }} €</h5>
          </div>
          <!-- bouton pour la validation du menu -->
          <div class="col-3">
            <button class="btn btn-primary float-right mr-5 mb-4" @click.prevent="VerifQteGarnitureLundi(),VerifQteGarnitureMardi(),VerifQteGarnitureMercredi(),VerifQteGarnitureJeudi(),updateCommande()">Modification de la commande</button>

          </div>

          
            

        </div>


        </div>
      </div>

        
    </div>
    
  </div>




</template>

<script>
import axios from 'axios'
import store from '../store/index'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'vue-select/dist/vue-select.css';



export default {
  
  name: 'ModifMenuSemaine1',

  data () {
    return{
      affichage: false,
      categorie: '',
      complet: false,
      porc: false,
      visible: false,
      id_utilisateur: 0,
      name: '',
      nameState: null,
      submittedNames: [],
      semaineSaisie: '',
      nom_plat: "",
      uniqueSaisie: "",
      allPlats: [],
      allmenus: [],
      allMenus: [],
      menuTraitement: [],
      plat: '',
      montantCde: 0,
      qteCommande: 0,
      allTarif: [],
      // tarifId1: '',
      // semaine1Tarif: [],
      // semaine2Tarif: '',
      // semaine3Tarif: '',
      // semaine4Tarif: '',
      // semaine5Tarif: '',
      TarifSemaine: '',
      semaine_tarifActif: '',
      semaine1: moment(String(new Date())).format('W-YYYY'),
      semaine1Jour: moment().isoWeekday(this.semaine1),
      jour1: '',
      jour2: '',
      jour3: '',
      jour4: '',
      semaine2: moment(String(new Date())).add(7, 'days').format('W-YYYY'),
      semaine3: moment(String(new Date())).add(14, 'days').format('W-YYYY'),
      semaine4: moment(String(new Date())).add(21, 'days').format('W-YYYY'),
      cle_plat1: [],
      cle_plat2: [],
      cle_plat3: [],
      cle_plat4: [],
      cle_plat5: [],
      cle_plat6: [],
      cle_plat7: [],
      cle_plat8: [],
      cle_plat9: [],
      cle_plat10: [],
      cle_plat11: [],
      cle_plat12: [],
      cle_plat13: [],
      cle_plat14: [],
      cle_plat15: [],
      cle_plat16: [],
      cle_plat17: [],
      cle_plat18: [],
      cle_plat19: [],
      cle_plat20: [],
      cle_plat21: [],
      cle_plat22: [],
      cle_plat23: [],
      cle_plat24: [],
      cle_plat25: [],
      cle_plat26: [],
      cle_plat27: [],
      cle_plat28: [],
      cle_plat29: [],
      cle_plat30: [],
      cle_plat31: [],
      cle_plat32: [],
      cle_plat33: [],
      cle_plat34: [],
      cle_plat35: [],
      cle_plat36: [],
      cle_plat37: [],
      cle_plat38: [],
      cle_plat39: [],
      cle_plat40: [],
      cle_plat41: [],
      cle_plat42: [],
      cle_plat43: [],
      cle_plat44: [],
      cle_plat45: [],
      cle_plat46: [],
      cle_plat47: [],
      cle_plat48: [],
      cle_plat49: [],
      cle_plat50: [],
      cle_plat51: [],
      cle_plat52: [],
      cle_plat53: [],
      cle_plat54: [],
      cle_plat55: [],
      cle_plat56: [],
      cle_plat57: [],
      cle_plat58: [],
      cle_plat59: [],
      cle_plat60: [],
      cle_plat61: [],
      cle_plat62: [],
      cle_plat63: [],
      cle_plat64: [],
      cle_plat65: [],
      cle_plat66: [],
      cle_plat67: [],
      cle_plat68: [],
      qt_plat1: 0,
      qt_plat2: 0,
      qt_plat3: 0,
      qt_plat4: 0,
      qt_plat5: 0,
      qt_plat6: 0,
      qt_plat7: 0,
      qt_plat8: 0,
      qt_plat9: 0,
      qt_plat10: 0,
      qt_plat11: 0,
      qt_plat12: 0,
      qt_plat13: 0,
      qt_plat14: 0,
      qt_plat15: 0,
      qt_plat16: 0,
      qt_plat17: 0,
      qt_plat18: 0,
      qt_plat19: 0,
      qt_plat20: 0,
      qt_plat21: 0,
      qt_plat22: 0,
      qt_plat23: 0,
      qt_plat24: 0,
      qt_plat25: 0,
      qt_plat26: 0,
      qt_plat27: 0,
      qt_plat28: 0,
      qt_plat29: 0,
      qt_plat30: 0,
      qt_plat31: 0,
      qt_plat32: 0,
      qt_plat33: 0,
      qt_plat34: 0,
      qt_plat35: 0,
      qt_plat36: 0,
      qt_plat37: 0,
      qt_plat38: 0,
      qt_plat39: 0,
      qt_plat40: 0,
      qt_plat41: 0,
      qt_plat42: 0,
      qt_plat43: 0,
      qt_plat44: 0,
      qt_plat45: 0,
      qt_plat46: 0,
      qt_plat47: 0,
      qt_plat48: 0,
      qt_plat49: 0,
      qt_plat50: 0,
      qt_plat51: 0,
      qt_plat52: 0,
      qt_plat53: 0,
      qt_plat54: 0,
      qt_plat55: 0,
      qt_plat56: 0,
      qt_plat57: 0,
      qt_plat58: 0,
      qt_plat59: 0,
      qt_plat60: 0,
      qt_plat61: 0,
      qt_plat62: 0,
      qt_plat63: 0,
      qt_plat64: 0,
      qt_plat65: 0,
      qt_plat66: 0,
      qt_plat67: 0,
      qt_plat68: 0,
      ErreurLundiManque: false,
      ErreurMardiManque: false,
      ErreurMercrediManque: false,
      ErreurJeudiManque: false,
      ErreurLundiTrop: false,
      ErreurMardiTrop: false,
      ErreurMercrediTrop: false,
      ErreurJeudiTrop: false,
      TotalPlatLundi: 0,
      TotalPlatMardi: 0,
      TotalPlatMercredi: 0,
      TotalPlatJeudi: 0,
      GarnitureLundi: 0,
      GarnitureMardi: 0,
      GarnitureMercredi: 0,
      GarnitureJeudi: 0,
      EcartLundi: 0,
      EcartMardi: 0,
      EcartMercredi: 0,
      EcartJeudi: 0,
      qtePlat5: 0,
      qtePlat6: 0,
      qtePlat7: 0,
      qtePlat8: 0,
      qtePlat9: 0,
      qtePlat10: 0,
      qtePlat11: 0,
      qtePlat22: 0,
      qtePlat23: 0,
      qtePlat24: 0,
      qtePlat25: 0,
      qtePlat26: 0,
      qtePlat27: 0,
      qtePlat28: 0,
      qtePlat39: 0,
      qtePlat40: 0,
      qtePlat41: 0,
      qtePlat42: 0,
      qtePlat43: 0,
      qtePlat44: 0,
      qtePlat45: 0,
      qtePlat56: 0,
      qtePlat57: 0,
      qtePlat58: 0,
      qtePlat59: 0,
      qtePlat60: 0,
      qtePlat61: 0,
      qtePlat62: 0,
    }

  },

  methods: {

    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
      },
      onChange(event) {
            console.log(event.target.value)
        },
      resetModal() {
        this.name = ''
        this.nameState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        // Push the name to submitted names
        this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      },
      supSaisieLundi() {
        this.qt_plat1 = 0
        this.qt_plat2 = 0
        this.qt_plat3 = 0
        this.qt_plat4 = 0
        this.qt_plat5 = 0
        this.qt_plat6 = 0
        this.qt_plat7 = 0
        this.qt_plat8 = 0
        this.qt_plat9 = 0
        this.qt_plat10 = 0
        this.qt_plat11 = 0
        this.qt_plat12 = 0
        this.qt_plat13 = 0
        this.qt_plat14 = 0
        this.qt_plat15 = 0
        this.qt_plat16 = 0        
        this.qt_plat17 = 0        
      },
      supSaisieMardi() {
        this.qt_plat18 = 0
        this.qt_plat19 = 0
        this.qt_plat20 = 0
        this.qt_plat21 = 0
        this.qt_plat22 = 0
        this.qt_plat23 = 0
        this.qt_plat24 = 0
        this.qt_plat25 = 0
        this.qt_plat26 = 0
        this.qt_plat27 = 0
        this.qt_plat28 = 0
        this.qt_plat29 = 0
        this.qt_plat30 = 0
        this.qt_plat31 = 0        
        this.qt_plat32 = 0        
        this.qt_plat33 = 0        
        this.qt_plat34 = 0        
      },
      supSaisieMercredi() {
        this.qt_plat35 = 0
        this.qt_plat36 = 0
        this.qt_plat37 = 0
        this.qt_plat38 = 0
        this.qt_plat39 = 0
        this.qt_plat40 = 0
        this.qt_plat41 = 0
        this.qt_plat42 = 0
        this.qt_plat43 = 0
        this.qt_plat44 = 0
        this.qt_plat45 = 0
        this.qt_plat46 = 0        
        this.qt_plat47 = 0        
        this.qt_plat48 = 0        
        this.qt_plat49 = 0        
        this.qt_plat50 = 0        
        this.qt_plat51 = 0        
      },
      supSaisieJeudi() {
        this.qt_plat52 = 0
        this.qt_plat53 = 0
        this.qt_plat54 = 0
        this.qt_plat55 = 0
        this.qt_plat56 = 0
        this.qt_plat57 = 0
        this.qt_plat58 = 0
        this.qt_plat59 = 0
        this.qt_plat60 = 0
        this.qt_plat61 = 0        
        this.qt_plat62 = 0        
        this.qt_plat63 = 0        
        this.qt_plat64 = 0        
        this.qt_plat65 = 0        
        this.qt_plat66 = 0        
        this.qt_plat67 = 0        
        this.qt_plat68 = 0        
      },

      CalculMontantCommande(){
        // Calcul des Qté d'entrées en commande
        let totalEntreeQte = parseInt(this.qt_plat1) + parseInt(this.qt_plat2) + parseInt(this.qt_plat3) + parseInt(this.qt_plat4) + parseInt(this.qt_plat18) + parseInt(this.qt_plat19) + parseInt(this.qt_plat20) + parseInt(this.qt_plat21) + parseInt(this.qt_plat35) + parseInt(this.qt_plat36) + parseInt(this.qt_plat37) + parseInt(this.qt_plat38) + parseInt(this.qt_plat52) + parseInt(this.qt_plat53) + parseInt(this.qt_plat54) + parseInt(this.qt_plat55)
        // Calcul du montant des entrées
        let totalEntree = totalEntreeQte * this.allTarif[0].prix_entree

        // Calcul des Qté de plats en commande
        let totalPlatQte = parseInt(this.qt_plat5) + parseInt(this.qt_plat6) + parseInt(this.qt_plat7) + parseInt(this.qt_plat8) + parseInt(this.qt_plat22) + parseInt(this.qt_plat23) + parseInt(this.qt_plat24) + parseInt(this.qt_plat25) + parseInt(this.qt_plat39) + parseInt(this.qt_plat40) + parseInt(this.qt_plat41) + parseInt(this.qt_plat42) + parseInt(this.qt_plat56) + parseInt(this.qt_plat57) + parseInt(this.qt_plat58) + parseInt(this.qt_plat59)
        // Calcul du montant des plats
        let totalPlat = totalPlatQte * this.allTarif[0].prix_plat

        // Calcul des Qté de fromages en commande
        let totalFromageQte = parseInt(this.qt_plat12) + parseInt(this.qt_plat13) + parseInt(this.qt_plat29) + parseInt(this.qt_plat30) + parseInt(this.qt_plat46) + parseInt(this.qt_plat47) + parseInt(this.qt_plat63) + parseInt(this.qt_plat64)
        // Calcul du montant des fromages
        let totalFromage = totalFromageQte * this.allTarif[0].prix_fromage

        // Calcul des Qté de pain en commande
        let totalPainQte = parseInt(this.qt_plat14) + parseInt(this.qt_plat31) + parseInt(this.qt_plat48) + parseInt(this.qt_plat65)
        // Calcul du montant des pains
        let totalPain = totalPainQte * this.allTarif[0].prix_pain

        // Calcul des Qté de desserts en commande
        let totaldessertQte = parseInt(this.qt_plat15) + parseInt(this.qt_plat16) + parseInt(this.qt_plat17) + parseInt(this.qt_plat32) + parseInt(this.qt_plat33) + parseInt(this.qt_plat34) + parseInt(this.qt_plat49) + parseInt(this.qt_plat50) + parseInt(this.qt_plat51) + parseInt(this.qt_plat66) + parseInt(this.qt_plat67) + parseInt(this.qt_plat68)
        // Calcul du montant des desserts
        let totaldessert = totaldessertQte * this.allTarif[0].prix_dessert



        this.qteCommande = totalEntreeQte + totalPlatQte + totalFromageQte + totalPainQte + totaldessertQte
        this.montantCde = totalEntree + totalPlat + totalFromage + totalPain + totaldessert
      },


      VerifQteGarnitureLundi(){
        //  Fonction pour comparer le nombre de plat et de garniture pour le lundi \\
        //  Si le résultat est 1 on a plus de garniture que de plat si -1 on a pas assez de garniture par rapport au plat

        // Vérification si on des plats complet, si plat complet alors on compte 0 sinon on compte la qté commandées
        if(this.cle_plat5[3]) {
          this.qtePlat5 = 0
        } else {
          this.qtePlat5 = this.qt_plat5
        }
        if(this.cle_plat6[3]) {
          this.qtePlat6 = 0
        } else {
          this.qtePlat6 = this.qt_plat6
        }
        if(this.cle_plat7[3]) {
          this.qtePlat7 = 0
        } else {
          this.qtePlat7 = this.qt_plat7
        }
        if(this.cle_plat8[3]) {
          this.qtePlat8 = 0
        } else {
          this.qtePlat8 = this.qt_plat8
        }

        // Récupération du nombre de garniture
        if(this.cle_plat9[3]) {
          this.qtePlat9 = 0
        } else {
          this.qtePlat9 = this.qt_plat9
        }
        if(this.cle_plat10[3]) {
          this.qtePlat10 = 0
        } else {
          this.qtePlat10 = this.qt_plat10
        }
        if(this.cle_plat11[3]) {
          this.qtePlat11 = 0
        } else {
          this.qtePlat11 = this.qt_plat11
        }
        
        // Calcul du nombre de plat qui doivent avoir une garniture
        this.TotalPlatLundi = parseInt(this.qtePlat5) + parseInt(this.qtePlat6) + parseInt(this.qtePlat7) + parseInt(this.qtePlat8)
        // Calcul du nombre de garniture en commande
        this.GarnitureLundi = parseInt(this.qtePlat9) + parseInt(this.qtePlat10) + parseInt(this.qtePlat11)
        
        // Envoie du resultat dans EcartLundi 
        if(this.TotalPlatLundi > this.GarnitureLundi) {
          this.EcartLundi = -1
          } else if (this.TotalPlatLundi < this.GarnitureLundi ) {
            this.EcartLundi = 1
          } else {
            this.EcartLundi = 0
          }
        
      },

      VerifQteGarnitureMardi(){
        //  Fonction pour comparer le nombre de plat et de garniture pour le Mardi \\
        //  Si le résultat est 1 on a plus de garniture que de plat si -1 on a pas assez de garniture par rapport au plat

        // Vérification si on des plats complet, si plat complet alors on compte 0 sinon on compte la qté commandées
        if(this.cle_plat22[3]) {
          this.qtePlat22 = 0
        } else {
          this.qtePlat22 = this.qt_plat22
        }
        if(this.cle_plat23[3]) {
          this.qtePlat23 = 0
        } else {
          this.qtePlat23 = this.qt_plat23
        }
        if(this.cle_plat24[3]) {
          this.qtePlat24 = 0
        } else {
          this.qtePlat24 = this.qt_plat24
        }
        if(this.cle_plat25[3]) {
          this.qtePlat25 = 0
        } else {
          this.qtePlat25 = this.qt_plat25
        }

        // Récupération du nombre de garniture
        if(this.cle_plat26[3]) {
          this.qtePlat26 = 0
        } else {
          this.qtePlat26 = this.qt_plat26
        }
        if(this.cle_plat27[3]) {
          this.qtePlat27 = 0
        } else {
          this.qtePlat10 = this.qt_plat10
        }
        if(this.cle_plat28[3]) {
          this.qtePlat28 = 0
        } else {
          this.qtePlat28 = this.qt_plat28
        }
        
        // Calcul du nombre de plat qui doivent avoir une garniture
        this.TotalPlatMardi = parseInt(this.qtePlat22) + parseInt(this.qtePlat23) + parseInt(this.qtePlat24) + parseInt(this.qtePlat25)
        // Calcul du nombre de garniture en commande
        this.GarnitureMardi = parseInt(this.qtePlat26) + parseInt(this.qtePlat27) + parseInt(this.qtePlat28)
        
        // Envoie du resultat dans EcartLundi 
        if(this.TotalPlatMardi > this.GarnitureMardi) {
          this.EcartMardi = -1
          } else if (this.TotalPlatMardi < this.GarnitureMardi ) {
            this.EcartMardi = 1
          } else {
            this.EcartMardi = 0
          }
        
      },

      VerifQteGarnitureMercredi(){
        //  Fonction pour comparer le nombre de plat et de garniture pour le Mercredi \\
        //  Si le résultat est 1 on a plus de garniture que de plat si -1 on a pas assez de garniture par rapport au plat

        // Vérification si on des plats complet, si plat complet alors on compte 0 sinon on compte la qté commandées
        if(this.cle_plat39[3]) {
          this.qtePlat39 = 0
        } else {
          this.qtePlat39 = this.qt_plat39
        }
        if(this.cle_plat40[3]) {
          this.qtePlat40 = 0
        } else {
          this.qtePlat40 = this.qt_plat40
        }
        if(this.cle_plat41[3]) {
          this.qtePlat41 = 0
        } else {
          this.qtePlat41 = this.qt_plat41
        }
        if(this.cle_plat42[3]) {
          this.qtePlat42 = 0
        } else {
          this.qtePlat42 = this.qt_plat42
        }

        // Récupération du nombre de garniture
        if(this.cle_plat43[3]) {
          this.qtePlat43 = 0
        } else {
          this.qtePlat43 = this.qt_plat43
        }
        if(this.cle_plat44[3]) {
          this.qtePlat44 = 0
        } else {
          this.qtePlat44 = this.qt_plat44
        }
        if(this.cle_plat45[3]) {
          this.qtePlat45 = 0
        } else {
          this.qtePlat45 = this.qt_plat45
        }
        
        // Calcul du nombre de plat qui doivent avoir une garniture
        this.TotalPlatMercredi = parseInt(this.qtePlat39) + parseInt(this.qtePlat40) + parseInt(this.qtePlat41) + parseInt(this.qtePlat42)
        // Calcul du nombre de garniture en commande
        this.GarnitureMercredi = parseInt(this.qtePlat43) + parseInt(this.qtePlat44) + parseInt(this.qtePlat45)
        
        // Envoie du resultat dans EcartLundi 
        if(this.TotalPlatMercredi > this.GarnitureMercredi) {
          this.EcartMercredi = -1
          } else if (this.TotalPlatMercredi < this.GarnitureMercredi ) {
            this.EcartMercredi = 1
          } else {
            this.EcartMercredi = 0
          }
        
      },

      VerifQteGarnitureJeudi(){
        //  Fonction pour comparer le nombre de plat et de garniture pour le Jeudi \\
        //  Si le résultat est 1 on a plus de garniture que de plat si -1 on a pas assez de garniture par rapport au plat

        // Vérification si on des plats complet, si plat complet alors on compte 0 sinon on compte la qté commandées
        if(this.cle_plat56[3]) {
          this.qtePlat56 = 0
        } else {
          this.qtePlat56 = this.qt_plat56
        }
        if(this.cle_plat57[3]) {
          this.qtePlat57 = 0
        } else {
          this.qtePlat57 = this.qt_plat57
        }
        if(this.cle_plat58[3]) {
          this.qtePlat58 = 0
        } else {
          this.qtePlat58 = this.qt_plat58
        }
        if(this.cle_plat59[3]) {
          this.qtePlat59 = 0
        } else {
          this.qtePlat59 = this.qt_plat59
        }

        // Récupération du nombre de garniture
        if(this.cle_plat60[3]) {
          this.qtePlat60 = 0
        } else {
          this.qtePlat60 = this.qt_plat60
        }
        if(this.cle_plat61[3]) {
          this.qtePlat61 = 0
        } else {
          this.qtePlat61 = this.qt_plat61
        }
        if(this.cle_plat62[3]) {
          this.qtePlat62 = 0
        } else {
          this.qtePlat62 = this.qt_plat62
        }
        
        // Calcul du nombre de plat qui doivent avoir une garniture
        this.TotalPlatJeudi = parseInt(this.qtePlat56) + parseInt(this.qtePlat57) + parseInt(this.qtePlat58) + parseInt(this.qtePlat59)
        // Calcul du nombre de garniture en commande
        this.GarnitureJeudi = parseInt(this.qtePlat60) + parseInt(this.qtePlat61) + parseInt(this.qtePlat62)
        
        // Envoie du resultat dans EcartLundi 
        if(this.TotalPlatJeudi > this.GarnitureJeudi) {
          this.EcartJeudi = -1
          } else if (this.TotalPlatJeudi < this.GarnitureJeudi ) {
            this.EcartJeudi = 1
          } else {
            this.EcartJeudi = 0
          }
        
      },

      EffaceMessage(){
        this.ErreurLundiManque = false;
        this.ErreurLundiTrop = false;
        this.ErreurMardiManque = false;
        this.ErreurMercrediManque = false;
        this.ErreurJeudiManque = false;
        this.ErreurLundiTrop = false;
        this.ErreurMardiTrop = false;
        this.ErreurMercrediTrop = false;
        this.ErreurJeudiTrop = false;
      },



    retour(){
      this.$router.push('/connecter')
    },



    importMenu(){
      console.log(this.semaineSaisie);
      axios.get(`http://localhost:3000/menu/${this.semaineSaisie}`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
        .then(response => {
          this.semaine_tarifActif = response.data.menus[0].tarif_menu
          this.uniqueSaisie = store.state.userId + this.semaineSaisie
          this.allMenus = []
          this.allMenusImport = response.data.menus[0]
          // récupération et tri des données du menu
          let menuTraitement = []
          menuTraitement=(Object.values(this.allMenusImport).slice(5,73));
          console.log(menuTraitement)
          
          // ajout du menu
          let menuTemp = []
          for (let i = 0; i < menuTraitement.length; i++){
            console.log("Plat en cours menu traitement :" + menuTraitement[i])
            if (typeof menuTraitement[i] === 'number'){
              for (let j in this.allPlats){
                console.log("deuxieme for");
                if (menuTraitement[i] == this.allPlats[j].platId){
                  console.log("plat id : " + this.allPlats[j].platId)
                  console.log("Nom du plat : " + this.allPlats[j].nom_plat)
                  console.log("Catégorie : " + this.allPlats[j].categorie)
                  console.log("Complet : " + this.allPlats[j].complet)
                  console.log("Porc : " + this.allPlats[j].porc)
                  
                  menuTemp.push([
                                            this.allPlats[j].platId,
                                            this.allPlats[j].nom_plat,
                                            this.allPlats[j].categorie,
                                            this.allPlats[j].complet,
                                            this.allPlats[j].porc])
                }
                
              }
            } else if (typeof menuTraitement[i] != 'number'){
              menuTemp.push([
                                            0,
                                            'vide',
                                            'videcategorie',
                                            false,
                                            false])
            }
          }
          console.log('le push est : ' + menuTemp);          
          this.allMenus.push(menuTemp)
          console.log('apres le push ' + this.allMenus);
          this.cle_plat1 = this.allMenus[0][0]
          this.cle_plat2 = this.allMenus[0][1]
          this.cle_plat3 = this.allMenus[0][2]
          this.cle_plat4 = this.allMenus[0][3]
          this.cle_plat5 = this.allMenus[0][4]
          this.cle_plat6 = this.allMenus[0][5]
          this.cle_plat7 = this.allMenus[0][6]
          this.cle_plat8 = this.allMenus[0][7]
          this.cle_plat9 = this.allMenus[0][8]
          this.cle_plat10 = this.allMenus[0][9]
          this.cle_plat11 = this.allMenus[0][10]
          this.cle_plat12 = this.allMenus[0][11]
          this.cle_plat13 = this.allMenus[0][12]
          this.cle_plat14 = this.allMenus[0][13]
          this.cle_plat15 = this.allMenus[0][14]
          this.cle_plat16 = this.allMenus[0][15]
          this.cle_plat17 = this.allMenus[0][16]
          this.cle_plat18 = this.allMenus[0][17]
          this.cle_plat19 = this.allMenus[0][18]
          this.cle_plat20 = this.allMenus[0][19]
          this.cle_plat21 = this.allMenus[0][20]
          this.cle_plat22 = this.allMenus[0][21]
          this.cle_plat23 = this.allMenus[0][22]
          this.cle_plat24 = this.allMenus[0][23]
          this.cle_plat25 = this.allMenus[0][24]
          this.cle_plat26 = this.allMenus[0][25]
          this.cle_plat27 = this.allMenus[0][26]
          this.cle_plat28 = this.allMenus[0][27]
          this.cle_plat29 = this.allMenus[0][28]
          this.cle_plat30 = this.allMenus[0][29]
          this.cle_plat31 = this.allMenus[0][30]
          this.cle_plat32 = this.allMenus[0][31]
          this.cle_plat33 = this.allMenus[0][32]
          this.cle_plat34 = this.allMenus[0][33]
          this.cle_plat35 = this.allMenus[0][34]
          this.cle_plat36 = this.allMenus[0][35]
          this.cle_plat37 = this.allMenus[0][36]
          this.cle_plat38 = this.allMenus[0][37]
          this.cle_plat39 = this.allMenus[0][38]
          this.cle_plat40 = this.allMenus[0][39]
          this.cle_plat41 = this.allMenus[0][40]
          this.cle_plat42 = this.allMenus[0][41]
          this.cle_plat43 = this.allMenus[0][42]
          this.cle_plat44 = this.allMenus[0][43]
          this.cle_plat45 = this.allMenus[0][44]
          this.cle_plat46 = this.allMenus[0][45]
          this.cle_plat47 = this.allMenus[0][46]
          this.cle_plat48 = this.allMenus[0][47]
          this.cle_plat49 = this.allMenus[0][48]
          this.cle_plat50 = this.allMenus[0][49]
          this.cle_plat51 = this.allMenus[0][50]
          this.cle_plat52 = this.allMenus[0][51]
          this.cle_plat53 = this.allMenus[0][52]
          this.cle_plat54 = this.allMenus[0][53]
          this.cle_plat55 = this.allMenus[0][54]
          this.cle_plat56 = this.allMenus[0][55]
          this.cle_plat57 = this.allMenus[0][56]
          this.cle_plat58 = this.allMenus[0][57]
          this.cle_plat59 = this.allMenus[0][58]
          this.cle_plat60 = this.allMenus[0][59]
          this.cle_plat61 = this.allMenus[0][60]
          this.cle_plat62 = this.allMenus[0][61]
          this.cle_plat63 = this.allMenus[0][62]
          this.cle_plat64 = this.allMenus[0][63]
          this.cle_plat65 = this.allMenus[0][64]
          this.cle_plat66 = this.allMenus[0][65]
          this.cle_plat67 = this.allMenus[0][66]
          this.cle_plat68 = this.allMenus[0][67]

          axios.get(`http://localhost:3000/commande/${this.uniqueSaisie}`, {
          
            headers: {Authorization: `Bearer ${store.state.token}`}}
            )
          
          .then(response => {
            console.log(response.data.modifcde[0]);
            this.qt_plat1 = response.data.modifcde[0].qt_plat1
            this.qt_plat2 = response.data.modifcde[0].qt_plat2
            this.qt_plat3 = response.data.modifcde[0].qt_plat3
            this.qt_plat4 = response.data.modifcde[0].qt_plat4
            this.qt_plat5 = response.data.modifcde[0].qt_plat5
            this.qt_plat6 = response.data.modifcde[0].qt_plat6
            this.qt_plat7 = response.data.modifcde[0].qt_plat7
            this.qt_plat8 = response.data.modifcde[0].qt_plat8
            this.qt_plat9 = response.data.modifcde[0].qt_plat9
            this.qt_plat10 = response.data.modifcde[0].qt_plat10
            this.qt_plat11 = response.data.modifcde[0].qt_plat11
            this.qt_plat12 = response.data.modifcde[0].qt_plat12
            this.qt_plat13 = response.data.modifcde[0].qt_plat13
            this.qt_plat14 = response.data.modifcde[0].qt_plat14
            this.qt_plat15 = response.data.modifcde[0].qt_plat15
            this.qt_plat16 = response.data.modifcde[0].qt_plat16
            this.qt_plat17 = response.data.modifcde[0].qt_plat17
            this.qt_plat18 = response.data.modifcde[0].qt_plat18
            this.qt_plat19 = response.data.modifcde[0].qt_plat19
            this.qt_plat20 = response.data.modifcde[0].qt_plat20
            this.qt_plat21 = response.data.modifcde[0].qt_plat21
            this.qt_plat22 = response.data.modifcde[0].qt_plat22
            this.qt_plat23 = response.data.modifcde[0].qt_plat23
            this.qt_plat24 = response.data.modifcde[0].qt_plat24
            this.qt_plat25 = response.data.modifcde[0].qt_plat25
            this.qt_plat26 = response.data.modifcde[0].qt_plat26
            this.qt_plat27 = response.data.modifcde[0].qt_plat27
            this.qt_plat28 = response.data.modifcde[0].qt_plat28
            this.qt_plat29 = response.data.modifcde[0].qt_plat29
            this.qt_plat30 = response.data.modifcde[0].qt_plat30
            this.qt_plat31 = response.data.modifcde[0].qt_plat31
            this.qt_plat32 = response.data.modifcde[0].qt_plat32
            this.qt_plat33 = response.data.modifcde[0].qt_plat33
            this.qt_plat34 = response.data.modifcde[0].qt_plat34
            this.qt_plat35 = response.data.modifcde[0].qt_plat35
            this.qt_plat36 = response.data.modifcde[0].qt_plat36
            this.qt_plat37 = response.data.modifcde[0].qt_plat37
            this.qt_plat38 = response.data.modifcde[0].qt_plat38
            this.qt_plat39 = response.data.modifcde[0].qt_plat39
            this.qt_plat40 = response.data.modifcde[0].qt_plat40
            this.qt_plat41 = response.data.modifcde[0].qt_plat41
            this.qt_plat42 = response.data.modifcde[0].qt_plat42
            this.qt_plat43 = response.data.modifcde[0].qt_plat43
            this.qt_plat44 = response.data.modifcde[0].qt_plat44
            this.qt_plat45 = response.data.modifcde[0].qt_plat45
            this.qt_plat46 = response.data.modifcde[0].qt_plat46
            this.qt_plat47 = response.data.modifcde[0].qt_plat47
            this.qt_plat48 = response.data.modifcde[0].qt_plat48
            this.qt_plat49 = response.data.modifcde[0].qt_plat49
            this.qt_plat50 = response.data.modifcde[0].qt_plat50
            this.qt_plat51 = response.data.modifcde[0].qt_plat51
            this.qt_plat52 = response.data.modifcde[0].qt_plat52
            this.qt_plat53 = response.data.modifcde[0].qt_plat53
            this.qt_plat54 = response.data.modifcde[0].qt_plat54
            this.qt_plat55 = response.data.modifcde[0].qt_plat55
            this.qt_plat56 = response.data.modifcde[0].qt_plat56
            this.qt_plat57 = response.data.modifcde[0].qt_plat57
            this.qt_plat58 = response.data.modifcde[0].qt_plat58
            this.qt_plat59 = response.data.modifcde[0].qt_plat59
            this.qt_plat60 = response.data.modifcde[0].qt_plat60
            this.qt_plat61 = response.data.modifcde[0].qt_plat61
            this.qt_plat62 = response.data.modifcde[0].qt_plat62
            this.qt_plat63 = response.data.modifcde[0].qt_plat63
            this.qt_plat64 = response.data.modifcde[0].qt_plat64
            this.qt_plat65 = response.data.modifcde[0].qt_plat65
            this.qt_plat66 = response.data.modifcde[0].qt_plat66
            this.qt_plat67 = response.data.modifcde[0].qt_plat67
            this.qt_plat68 = response.data.modifcde[0].qt_plat68
            this.montantCde = response.data.modifcde[0].prix_total
          })
        })
        
        
      .catch(error => {console.log('erreur import menu:', error.response);})

      .then(() =>{
          console.log("Passage dans l'appel des tarifs")
          console.log("La semaine active est " + this.semaine_tarifActif);
          this.semaine_tarif = this.semaine_tarifActif
          console.log("La semaine active est " + this.semaine_tarif);
      axios.get(`http://localhost:3000/tarif/${this.semaine_tarif}`, {
        
        },
        {headers: {Authorization: `Bearer ${store.state.token}`},}
        )
        
        .then(response => {
            console.log(response.data.tarif);
            for(const tarif of response.data.tarif){
            this.allTarif.push(tarif)
            }
            this.allTarif.forEach(tarif => {
            this.profil.push(tarif.tarifId)
            })     
        })
        .catch(error => {console.log('An error occurred:', error.response);})
        
        
      .catch(error => {console.log('erreur import menu:', error.response);})
      })
    },


    updateCommande(){
      this.ErreurLundiManque = false;
        this.ErreurLundiTrop = false;
        this.ErreurMardiManque = false;
        this.ErreurMercrediManque = false;
        this.ErreurJeudiManque = false;
        this.ErreurLundiTrop = false;
        this.ErreurMardiTrop = false;
        this.ErreurMercrediTrop = false;
        this.ErreurJeudiTrop = false;
        if (this.EcartLundi == -1){
          return this.ErreurLundiManque = true;
        } else if (this.EcartLundi == 1) {
          return this.ErreurLundiTrop = true;
          } else if (this.EcartMardi == -1) {
            return this.ErreurMardiManque = true;
            } else if (this.EcartMardi == 1) {
              return this.ErreurMardiTrop = true;
              } else if (this.EcartMercredi == -1) {
                return this.ErreurMercrediManque = true;
                } else if (this.EcartMercredi == 1) {
                  return this.ErreurMercrediTrop = true;
                  } else if (this.EcartJeudi == -1) {
                    return this.ErreurJeudiManque = true;
                    } else if (this.EcartJeudi == 1) {
                      return this.ErreurJeudiTrop = true;
                      } else {

      console.log("la valeur est : " + this.qt_plat1,);
      axios.put(`http://localhost:3000/commande/${this.uniqueSaisie}`, {
        prix_total: this.montantCde,
        qt_plat1: this.qt_plat1,
        qt_plat2: this.qt_plat2,
        qt_plat3: this.qt_plat3,
        qt_plat4: this.qt_plat4,
        qt_plat5: this.qt_plat5,
        qt_plat6: this.qt_plat6,
        qt_plat7: this.qt_plat7,
        qt_plat8: this.qt_plat8,
        qt_plat9: this.qt_plat9,
        qt_plat10: this.qt_plat10,
        qt_plat11: this.qt_plat11,
        qt_plat12: this.qt_plat12,
        qt_plat13: this.qt_plat13,
        qt_plat14: this.qt_plat14,
        qt_plat15: this.qt_plat15,
        qt_plat16: this.qt_plat16,
        qt_plat17: this.qt_plat17,
        qt_plat18: this.qt_plat18,
        qt_plat19: this.qt_plat19,
        qt_plat20: this.qt_plat20,
        qt_plat21: this.qt_plat21,
        qt_plat22: this.qt_plat22,
        qt_plat23: this.qt_plat23,
        qt_plat24: this.qt_plat24,
        qt_plat25: this.qt_plat25,
        qt_plat26: this.qt_plat26,
        qt_plat27: this.qt_plat27,
        qt_plat28: this.qt_plat28,
        qt_plat29: this.qt_plat29,
        qt_plat30: this.qt_plat30,
        qt_plat31: this.qt_plat31,
        qt_plat32: this.qt_plat32,
        qt_plat33: this.qt_plat33,
        qt_plat34: this.qt_plat34,
        qt_plat35: this.qt_plat35,
        qt_plat36: this.qt_plat36,
        qt_plat37: this.qt_plat37,
        qt_plat38: this.qt_plat38,
        qt_plat39: this.qt_plat39,
        qt_plat40: this.qt_plat40,
        qt_plat41: this.qt_plat41,
        qt_plat42: this.qt_plat42,
        qt_plat43: this.qt_plat43,
        qt_plat44: this.qt_plat44,
        qt_plat45: this.qt_plat45,
        qt_plat46: this.qt_plat46,
        qt_plat47: this.qt_plat47,
        qt_plat48: this.qt_plat48,
        qt_plat49: this.qt_plat49,
        qt_plat50: this.qt_plat50,
        qt_plat51: this.qt_plat51,
        qt_plat52: this.qt_plat52,
        qt_plat53: this.qt_plat53,
        qt_plat54: this.qt_plat54,
        qt_plat55: this.qt_plat55,
        qt_plat56: this.qt_plat56,
        qt_plat57: this.qt_plat57,
        qt_plat58: this.qt_plat58,
        qt_plat59: this.qt_plat59,
        qt_plat60: this.qt_plat60,
        qt_plat61: this.qt_plat61,
        qt_plat62: this.qt_plat62,
        qt_plat63: this.qt_plat63,
        qt_plat64: this.qt_plat64,
        qt_plat65: this.qt_plat65,
        qt_plat66: this.qt_plat66,
        qt_plat67: this.qt_plat67,
        qt_plat68: this.qt_plat68,

      
      },
        { headers: { Authorization: `Bearer ${store.state.token}` },}
      )
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Menu mis à jour',
          showConfirmButton: false,
          timer: 2000
        })

        
      })
      .catch(error => {console.log("Menu non mis à jour: ", error.response);
        Swal.fire({
          title: "Une erreur est survenue",
          icon: "error",
          timer: 2500,
          showConfirmButton: false,
          timerProgressBar: true
        }) 
      })
      }
      setTimeout(this.retour, 1000)

    },

  },
  created () {
            document.title = "Ajout menu";
            this.semaineSaisie = this.semaine1;
  },

   mounted() {
  //récupération des plats
    axios.get(`http://localhost:3000/plats`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
        .then(response => {
          for(const plat of response.data.plat){
          this.allPlats.push(plat)
          }
          this.allPlats.forEach(plat => {
          this.profil.push(plat.platId)
          })     
        })
      .catch(error => {console.log('An error occurred:', error.response);})

      this.visible = true

      setTimeout(this.importMenu, 600)

      //Gestion des dates pour l'affichage dans la saisie des menus
      switch (this.semaine1Jour) {
        case 1:
          this.jour1 = moment(String(new Date())).format('Do MMMM');
          this.jour2 = moment(String(new Date())).add(+1 , 'days').format('DD/MM/YY');
          this.jour3 = moment(String(new Date())).add(+2 , 'days').format('DD/MM/YY');
          this.jour4 = moment(String(new Date())).add(+3 , 'days').format('DD/MM/YY');
          break;
        case 2: // Si le jours de la semaine est mardi
          this.jour1 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
          this.jour2 = moment(String(new Date())).add(+0 , 'days').format('DD/MM/YY');
          this.jour3 = moment(String(new Date())).add(+1 , 'days').format('DD/MM/YY');
          this.jour4 = moment(String(new Date())).add(+2 , 'days').format('DD/MM/YY');
          break;
        case 3: // Si le jours de la semaine est mercredi
        this.jour1 = moment(String(new Date())).add(-2 , 'days').format('DD/MM/YY');
        this.jour2 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
        this.jour3 = moment(String(new Date())).add(+0 , 'days').format('DD/MM/YY');
        this.jour4 = moment(String(new Date())).add(+1 , 'days').format('DD/MM/YY');
          break;
        case 4: // Si le jours de la semaine est jeudi
        this.jour1 = moment(String(new Date())).add(-3 , 'days').format('DD/MM/YY');
        this.jour2 = moment(String(new Date())).add(-2 , 'days').format('DD/MM/YY');
        this.jour3 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
        this.jour4 = moment(String(new Date())).add(+0 , 'days').format('DD/MM/YY');
          break;
        case 5: // Si le jours de la semaine est vendredi
        this.jour1 = moment(String(new Date())).add(-4 , 'days').format('DD/MM/YY');
        this.jour2 = moment(String(new Date())).add(-3 , 'days').format('DD/MM/YY');
        this.jour3 = moment(String(new Date())).add(-2 , 'days').format('DD/MM/YY');
        this.jour4 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
          break;    
        default:
          console.log('default');
      }
  },

}
</script>

<style>
.container {
  height: 100vh;
  z-index: 0;
}

.centre {
  width: 200px;
  margin: 10px auto 10px auto;
}
.fin {
  width: 200px;
  margin: 0 auto 0 65%;
}
.inpselect {
  width: 80%;
}
.titrecat {
  width: 10%;
}

.taille{
  font-size: 99%;
}
.labelFond {
  background-color: rgb(212, 211, 211);
  width: 50%;
  text-align: left;
  padding-left: 10px;
}

</style>