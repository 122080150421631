<template>
  <div class="container misePage pt-5 mb-4" v-if="$store.state.isModerateur">
    <div class="container " >
      <button @click="retour()" class="btn btn-success home mr-5 mb-2" >Retour Accueil</button>
      <input type="text" v-model="search" placeholder="Recherche par nom" class="recherche">
        <div class="row"  v-bind:key="index" v-for="(user, index) in filteredList">
          <div class="col mt-2">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-3">
                    <p>Utilisateur:</p>
                    <p class="pGras">{{ user.identifiant }}</p>
                  </div>
                  <div class="col-3">
                    <p>Nom:</p>
                    <p class="pGras">{{ user.nom }}</p>
                  </div>
                  <div class="col-3">
                    <p>Prénom:</p>
                    <p class="pGras">{{ user.prenom }}</p>
                  </div>
                  <div class="col-2">
                    <router-link :to="`/profil/${user.userId}`">
                    <p></p>
                      <button class="btn btn-warning mb-3 float-left">Modification  <i class="fas fa-arrow-right"></i></button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import store from '../store/index'

export default {
  name: 'UsersAll',
  data () {
    return{
      id: this.$route.params.id,
      user: '',
      allUsers: [],
      search: '',
    }
  },
    methods: {
    retour(){
      this.$router.push('/connecter')
      
    },

  },
 mounted() {
  //récupération des utilisateurs
  axios.get(`http://localhost:3000/profil`, {
    headers: {Authorization: `Bearer ${store.state.token}`}}
    )
      .then(response => {
        for(const utilisateur of response.data.users){
        this.allUsers.push(utilisateur)
        }
        this.allUsers.forEach(utilisateur => {
        this.profil.push(utilisateur.userId)
        })     
      })
    .catch(error => {console.log('An error occurred:', error.response);})
  },


  computed: {
    // filtre pour la recherche des utilisateurs
    filteredList(){
      if(this.search){
      return this.allUsers.filter((item)=>{
        return this.search.toLowerCase().split(' ').every(v => item.nom.toLowerCase().includes(v))
      })
      }else{
        return this.allUsers;
      }
    }
  }

 }


</script>

<style scoped>

.misePage {
  margin: 95px auto 0px auto;
  height: 110vh;
}
p {
  font-size: 1vw;
}
.pGras {
  font-weight: bold;
}
.recherche {
  width: 500px;
  margin-left: 150px;

}

</style>