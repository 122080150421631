<template>
  <div class="container-fluid pt-5 pb-5 mt-5 mb-4">
    <div class="card">
      <div class="card-header text-center">
        <h1>Création du menu de la semaine : {{ semaineSaisie }}</h1>
        
        
        <div class="row pt-2">
          <div class="col-3">
            <h4>Sélectionner la semaine de saisie</h4>
            <select v-model="semaineSaisie" class="form-select ml-5" aria-label="Default select example">
              <option disabled value="">Semaine</option>
              <option>{{ semaine1 }}</option>
              <option>{{ semaine2 }}</option>
              <option>{{ semaine3 }}</option>
              <option>{{ semaine4 }}</option>
            </select>  
          </div>

          <div class="col-3">
            <h4>Sélectionner la grille de prix</h4>
            <select v-model="TarifSemaine" class="form-select ml-5" aria-label="Default select example">
              <option disabled value="">Grille tarifaire</option>
              <option>{{ allTarif[0].semaine_tarif }}</option>
              <option>{{ allTarif[1].semaine_tarif }}</option>
              <option>{{ allTarif[2].semaine_tarif }}</option>
              <option>{{ allTarif[3].semaine_tarif }}</option>
              <!-- Lors de création de la base il faut impérativement créer 4 semaines de tarif -->
            </select>  
          </div>
          

          <div class="col-3">
            <h4>Date de fin de validité des saisies</h4>
            <div class="selecteurDate">
              <b-form-datepicker id="dateValidite" v-model="dateValidite"></b-form-datepicker>
              
            </div>
          </div>

          <div class="col-3">
            <h4>Activation  du menu</h4>
            <div>
              <b-form-checkbox size="lg" id="menuActif" v-model="menuActif" name="menuActif" value="true" unchecked-value="false">Menu actif pour saisie</b-form-checkbox>
            </div>
          </div>

        </div>
        
        <button @click="retour()" class="btn btn-success float-right" >Retour Accueil</button>
        

      </div>
      <div>
        
        <button class="btn btn-primary mr-3 ml-5 mt-1 lb-1" value="importer">Importer</button>

        <button @click="majBd()" class="btn btn-danger ml-5 mt-1" >Mise à jour des plats</button>
        
        <button @click="JourMenu()" class="btn btn-success ml-5 mt-1" >Mise à jour des dates</button>

        <div class = "row ml-5 mr-5 mb-5 mt-2">
          <div class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Lundi {{ jour1 }}</h2>
            </div>
            <!-- début lundi saisie-->
            <div class = "col border text-center mt-2">
              <!-- Entrées début -->
              <h3 class="inpselect mt-2">Entrées</h3>
              <h4 class="titrecat">C</h4>
              <h4><img class="cochon" src="../assets/cochong.png"/></h4>
           
              <!-- 1° choix entrées -->
              <div class="row justify-content-between pt-2">
                <!-- <v-select v-model="cle_plat1" class="inpselect" label="nom_plat" :options="allPlats"> </v-select> -->
                <v-select v-model="cle_plat1" class="inpselect" label="nom_plat" :options="allPlats"> </v-select>
                <div class="vide">
                  <label v-if="cle_plat1.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat1.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat1.porc == false" class="mr-3" for="porc">⬜</label>
                  <label v-else-if="cle_plat1.porc == true" class="mr-3" for="porc">⬛</label>
                </div>
              </div>
              <!-- 2° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat2" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat2.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat2.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat2.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat2.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat3" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat3.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat3.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat3.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat3.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat4" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat4.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat4.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat4.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat4.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat5" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat5.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat5.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat5.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat5.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat6" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat6.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat6.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat6.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat6.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat7" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat7.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat7.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat7.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat7.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat8" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat8.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat8.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat8.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat8.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat9" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat9.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat9.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat9.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat9.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat10" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat10.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat10.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat10.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat10.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat11" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat11.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat11.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat11.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat11.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat12" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat12.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat12.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat12.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat12.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat13" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat13.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat13.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat13.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat13.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Fromage fin -->

              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat14" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
              </div>

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat15" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat15.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat15.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat15.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat15.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat16" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat16.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat16.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat16.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat16.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat17" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat17.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat17.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat17.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat17.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Dessert fin -->

              

            </div>
            <!-- Fin lundi saisie-->
          </div>
          <div class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Mardi {{ jour2 }}</h2>
            </div>

            <!-- début Mardi saisie-->
            <div class = "col border text-center mt-2">
              <!-- Entrées début -->
              <h3 class="inpselect mt-2">Entrées</h3>
              <h4 class="titrecat">C</h4>
              <h4><img class="cochon" src="../assets/cochong.png"/></h4>
           
              <!-- 1° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat18" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat18.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat18.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat18.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat18.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat19" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat19.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat19.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat19.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat19.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat20" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat20.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat20.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat20.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat20.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat21" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat21.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat21.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat21.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat21.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat22" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat22.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat22.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat22.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat22.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat23" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat23.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat23.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat23.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat23.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat24" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat24.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat24.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat24.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat24.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat25" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat25.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat25.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat25.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat25.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat26" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat26.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat26.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat26.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat26.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat27" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat27.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat27.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat27.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat27.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat28" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat28.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat28.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat28.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat28.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat29" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat29.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat29.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat29.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat29.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat30" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat30.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat30.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat30.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat30.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              
              <!-- Fromage fin -->

              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat31" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
              </div>
              <!-- Fin Pain -->

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat32" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat32.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat32.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat32.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat32.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat33" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat33.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat33.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat33.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat33.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat34" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat34.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat34.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat34.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat34.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Dessert fin -->

              

            </div>
            <!-- Fin Mardi saisie-->

          </div>
          <div class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Mercredi {{ jour3 }}</h2>
            </div>

            <!-- début Mercredi saisie-->
            <div class = "col border text-center mt-2">
              <!-- Entrées début -->
              <h3 class="inpselect mt-2">Entrées</h3>
              <h4 class="titrecat">C</h4>
              <h4><img class="cochon" src="../assets/cochong.png"/></h4>
           
              <!-- 1° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat35" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat35.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat35.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat35.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat35.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat36" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat36.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat36.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat36.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat36.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat37" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat37.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat37.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat37.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat37.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat38" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat38.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat38.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat38.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat38.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat39" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat39.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat39.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat39.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat39.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat40" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat40.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat40.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat40.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat40.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat41" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat41.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat41.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat41.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat41.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat42" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat42.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat42.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat42.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat42.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat43" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat43.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat43.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat43.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat43.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat44" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat44.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat44.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat44.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat44.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat45" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat45.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat45.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat45.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat45.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat46" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat46.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat46.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat46.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat46.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat47" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat47.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat47.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat47.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat47.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Fromage fin -->

              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat48" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
              </div>
              <!-- Fin Pain -->

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat49" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat49.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat49.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat49.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat49.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat50" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat50.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat50.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat50.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat50.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat51" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat51.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat51.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat51.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat51.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Dessert fin -->

              

            </div>
            <!-- Fin Mercredi saisie-->

          </div>
          <div class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Jeudi {{ jour4 }}</h2>
            </div>

            <!-- début Jeudi saisie-->
            <div class = "col border text-center mt-2">
              <!-- Entrées début -->
              <h3 class="inpselect mt-2">Entrées</h3>
              <h4 class="titrecat">C</h4>
              <h4><img class="cochon" src="../assets/cochong.png"/></h4>
           
              <!-- 1° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat52" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat52.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat52.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat52.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat52.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat53" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat53.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat53.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat53.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat53.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat54" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat54.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat54.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat54.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat54.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat55" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat55.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat55.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat55.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat55.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat56" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat56.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat56.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat56.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat56.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat57" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat57.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat57.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat57.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat57.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat58" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat58.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat58.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat58.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat58.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat59" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat59.complet == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat59.complet == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat59.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat59.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat60" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat60.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat60.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat60.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat60.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat61" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat61.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat61.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat61.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat61.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat62" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat62.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat62.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat62.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat62.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat63" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat63.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat63.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat63.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat63.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat64" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat64.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat64.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat64.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat64.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              
              <!-- Fromage fin -->


              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat65" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
              </div>
              <!-- Fin Pain -->

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat66" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat66.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat66.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat66.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat66.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat67" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat67.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat67.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat67.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat67.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat68" class="inpselect" label="nom_plat" :options="allPlats"></v-select>
                <div class="vide">
                  <label v-if="cle_plat68.complet == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat68.complet == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat68.porc == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat68.porc == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Dessert fin -->

              

            </div>
            <!-- Fin Jeudi saisie-->

          </div>



        </div>
        <div>



          <!-- Ajout de la fenêtre modale en bootstrap -->


        <div>
          <!-- bouton pour l'ouverture de la création d'un plat -->
          <b-button class="btn-success float-left ml-5 mb-4" v-b-modal.modal-prevent-closing>Création d'un plat</b-button>
            <b-modal
              id="modal-prevent-closing"
              ref="modal"
              title="Création d'un plat"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit" >
                <div>
                  <select v-model="categorie">
                    <option disabled value="">Choisissez la catégorie de plat</option>
                    <option>Entrées</option>
                    <option>Main Course</option>
                    <option>Accompagnements</option>
                    <option>Fromages</option>
                    <option>pain</option>
                    <option>Desserts</option>
                  </select>
                  <br>
                </div>
                <div class="form-group mt-3">
                  <label class="ml-2" for="nom_plat">Entrez le nom du plat</label>
                  <input v-model="nom_plat" type="text" id="nom_plat" class="form-control">
                </div>
                <div class="form-group">
                  <label class="ml-2 case" for="complet">Plat complet</label>
                  <input v-model="complet" type="checkbox" id="complet" class="form-control case">
                </div>
                <div class="form-group">
                  <label class="ml-2 case" for="porc">Contient du porc</label>
                  <input v-model="porc" type="checkbox" id="porc" class="form-control case">
                </div>
              </form>
              <template #modal-footer>
                <div class="w-100">
                  <b-button variant="success" size="xl" class="float-right" @click.prevent="creationPlat()">Enregister le plat</b-button>
                </div>
              </template>
            </b-modal>
            <!-- bouton pour la validation du menu -->
            <button class="btn btn-primary float-right mr-5 mb-4" @click.prevent="postMenu()">Enregistrer le menu</button>
        </div>


        </div>
      </div>

        
    </div>
    
  </div>




</template>

<script>
import axios from 'axios'
import store from '../store/index'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'vue-select/dist/vue-select.css';
//import Modale from "./Modale";


export default {
  
  name: 'AjoutMenu',
  // components: {
  //   modale: Modale,

  // },
  data () {
    return{
      categoriePlat: ['Entrées', 'Main Course', 'Accompagnements', 'Fromages', 'Desserts'],
      categorie: '',
      dateValidite: '',
      menuActif: 'false',
      complet: false,
      porc: false,
      name: '',
      nameState: null,
      submittedNames: [],
      semaine: '',
      semaineSaisie: '',
      revele: false,
      nom_plat: "",
      liste: [],
      allPlats: [],
      plat: '',
      allTarif: [],
      tarifId1: '',
      semaine1Tarif: '',
      semaine2Tarif: '',
      semaine3Tarif: '',
      semaine4Tarif: '',
      semaine5Tarif: '',
      TarifSemaine: '',
      semaine1: moment(String(new Date())).format('W-YYYY'),
      semaine1Jour: moment().isoWeekday(this.semaine1),
      jour1: '',
      jour2: '',
      jour3: '',
      jour4: '',
      semaine2: moment(String(new Date())).add(7, 'days').format('W-YYYY'),
      semaine3: moment(String(new Date())).add(14, 'days').format('W-YYYY'),
      semaine4: moment(String(new Date())).add(21, 'days').format('W-YYYY'),
      cle_plat1: [],
      cle_plat2: [],
      cle_plat3: [],
      cle_plat4: [],
      cle_plat5: [],
      cle_plat6: [],
      cle_plat7: [],
      cle_plat8: [],
      cle_plat9: [],
      cle_plat10: [],
      cle_plat11: [],
      cle_plat12: [],
      cle_plat13: [],
      cle_plat14: [],
      cle_plat15: [],
      cle_plat16: [],
      cle_plat17: [],
      cle_plat18: [],
      cle_plat19: [],
      cle_plat20: [],
      cle_plat21: [],
      cle_plat22: [],
      cle_plat23: [],
      cle_plat24: [],
      cle_plat25: [],
      cle_plat26: [],
      cle_plat27: [],
      cle_plat28: [],
      cle_plat29: [],
      cle_plat30: [],
      cle_plat31: [],
      cle_plat32: [],
      cle_plat33: [],
      cle_plat34: [],
      cle_plat35: [],
      cle_plat36: [],
      cle_plat37: [],
      cle_plat38: [],
      cle_plat39: [],
      cle_plat40: [],
      cle_plat41: [],
      cle_plat42: [],
      cle_plat43: [],
      cle_plat44: [],
      cle_plat45: [],
      cle_plat46: [],
      cle_plat47: [],
      cle_plat48: [],
      cle_plat49: [],
      cle_plat50: [],
      cle_plat51: [],
      cle_plat52: [],
      cle_plat53: [],
      cle_plat54: [],
      cle_plat55: [],
      cle_plat56: [],
      cle_plat57: [],
      cle_plat58: [],
      cle_plat59: [],
      cle_plat60: [],
      cle_plat61: [],
      cle_plat62: [],
      cle_plat63: [],
      cle_plat64: [],
      cle_plat65: [],
      cle_plat66: [],
      cle_plat67: [],
      cle_plat68: [],

    }

  },

  methods: {
        toggleModale: function() {
      this.revele = !this.revele;
    },
    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
      },
      resetModal() {
        this.name = ''
        this.nameState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        // Push the name to submitted names
        this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      },
    creationPlat() {
          if (!this.checkFormValidity()) {
          return
        }

          axios.post('http://localhost:3000/plats', {
            categorie: this.categorie,
            complet: this.complet,
            porc: this.porc,
            nom_plat: this.nom_plat,
            
          },
            { headers: { Authorization: `Bearer ${store.state.token}` },}
          )
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Plat Créé avec succes',
              showConfirmButton: false,
              timer: 2000
            })
            this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
        })
          })
          .catch(error => {console.log("Menu non publié: ", error.response);
            Swal.fire({
              title: "Une erreur est survenue",
              icon: "error",
              timer: 2500,
              showConfirmButton: false,
              timerProgressBar: true
            }) 
          }) 
 
        }, 
    retour(){
      this.$router.push('/connecter')
    },
    majBd(){
      this.allPlats = [],
      axios.get(`http://localhost:3000/plats`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
        .then(response => {
          for(const plat of response.data.plat){
            this.allPlats.push(plat)}
            Swal.fire({
              icon: 'success',
                title: 'Mise à jour de la base de données',
                showConfirmButton: false,
                timer: 1500 })
          this.allPlats.forEach(plat => {
          this.profil.push(plat.platId)
          })  
        })
      .catch(error => {console.log('An error occurred:', error.response);})
    },

    JourMenu(){
        if(this.semaineSaisie == this.semaine1){
          //Gestion des dates pour l'affichage dans la saisie des menus
          switch (this.semaine1Jour) {
            case 1:
              this.jour1 = moment(String(new Date())).format('Do MMMM');
              this.jour2 = moment(String(new Date())).add(+1 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+2 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+3 , 'days').format('DD/MM/YY');
              break;
            case 2: // Si le jours de la semaine est mardi
              this.jour1 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
              this.jour2 = moment(String(new Date())).add(+0 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+1 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+2 , 'days').format('DD/MM/YY');
              break;
            case 3: // Si le jours de la semaine est mercredi
            this.jour1 = moment(String(new Date())).add(-2 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+0 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+1 , 'days').format('DD/MM/YY');
              break;
            case 4: // Si le jours de la semaine est jeudi
            this.jour1 = moment(String(new Date())).add(-3 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(-2 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+0 , 'days').format('DD/MM/YY');
              break;
            case 5: // Si le jours de la semaine est vendredi
            this.jour1 = moment(String(new Date())).add(-4 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(-3 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(-2 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
              break;    
            default:
              console.log('default');
          }
        } else if(this.semaineSaisie == this.semaine2) {
          //Gestion des dates pour l'affichage dans la saisie des menus
          switch (this.semaine1Jour) {
            case 1:
              this.jour1 = moment(String(new Date())).add(+7 , 'days').format('Do MMMM');
              this.jour2 = moment(String(new Date())).add(+8 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+9 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+10 , 'days').format('DD/MM/YY');
              break;
            case 2: // Si le jours de la semaine est mardi
              this.jour1 = moment(String(new Date())).add(+6 , 'days').format('DD/MM/YY');
              this.jour2 = moment(String(new Date())).add(+7 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+8 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+9 , 'days').format('DD/MM/YY');
              break;
            case 3: // Si le jours de la semaine est mercredi
            this.jour1 = moment(String(new Date())).add(+5 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+6 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+7 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+8 , 'days').format('DD/MM/YY');
              break;
            case 4: // Si le jours de la semaine est jeudi
            this.jour1 = moment(String(new Date())).add(+4 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+5 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+6 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+7 , 'days').format('DD/MM/YY');
              break;
            case 5: // Si le jours de la semaine est vendredi
            this.jour1 = moment(String(new Date())).add(+3 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+4 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+5 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+6 , 'days').format('DD/MM/YY');
              break;    
            default:
              console.log('default');
          }
        } else if(this.semaineSaisie == this.semaine3){
          //Gestion des dates pour l'affichage dans la saisie des menus
          switch (this.semaine1Jour) {
            case 1:
              this.jour1 = moment(String(new Date())).add(+14 , 'days').format('Do MMMM');
              this.jour2 = moment(String(new Date())).add(+15 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+16 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+17 , 'days').format('DD/MM/YY');
              break;
            case 2: // Si le jours de la semaine est mardi
              this.jour1 = moment(String(new Date())).add(+13 , 'days').format('DD/MM/YY');
              this.jour2 = moment(String(new Date())).add(+14 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+15 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+16 , 'days').format('DD/MM/YY');
              break;
            case 3: // Si le jours de la semaine est mercredi
            this.jour1 = moment(String(new Date())).add(+12 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+13 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+14 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+15 , 'days').format('DD/MM/YY');
              break;
            case 4: // Si le jours de la semaine est jeudi
            this.jour1 = moment(String(new Date())).add(+11 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+12 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+13 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+14 , 'days').format('DD/MM/YY');
              break;
            case 5: // Si le jours de la semaine est vendredi
            this.jour1 = moment(String(new Date())).add(+10 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+11 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+12 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+13 , 'days').format('DD/MM/YY');
              break;    
            default:
              console.log('default');
          }
        } else if(this.semaineSaisie == this.semaine4){
          //Gestion des dates pour l'affichage dans la saisie des menus
          switch (this.semaine1Jour) {
            case 1:
              this.jour1 = moment(String(new Date())).add(+21 , 'days').format('Do MMMM');
              this.jour2 = moment(String(new Date())).add(+22 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+23 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+24 , 'days').format('DD/MM/YY');
              break;
            case 2: // Si le jours de la semaine est mardi
              this.jour1 = moment(String(new Date())).add(+20 , 'days').format('DD/MM/YY');
              this.jour2 = moment(String(new Date())).add(+21 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+22 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+23 , 'days').format('DD/MM/YY');
              break;
            case 3: // Si le jours de la semaine est mercredi
            this.jour1 = moment(String(new Date())).add(+19 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+20 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+21 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+22 , 'days').format('DD/MM/YY');
              break;
            case 4: // Si le jours de la semaine est jeudi
            this.jour1 = moment(String(new Date())).add(+18 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+19 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+20 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+21 , 'days').format('DD/MM/YY');
              break;
            case 5: // Si le jours de la semaine est vendredi
            this.jour1 = moment(String(new Date())).add(+17 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+18 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+19 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+20 , 'days').format('DD/MM/YY');
              break;    
            default:
              console.log('default');
          }
        }
      },


    postMenu(){
      axios.post('http://localhost:3000/menu', {
        semaine_menu: this.semaineSaisie,
        date_validite: this.dateValidite,
        tarif_menu: this.TarifSemaine,
        menuActif: this.menuActif,
        cle_plat1: this.cle_plat1.platId,
        cle_plat2: this.cle_plat2.platId,
        cle_plat3: this.cle_plat3.platId,
        cle_plat4: this.cle_plat4.platId,
        cle_plat5: this.cle_plat5.platId,
        cle_plat6: this.cle_plat6.platId,
        cle_plat7: this.cle_plat7.platId,
        cle_plat8: this.cle_plat8.platId,
        cle_plat9: this.cle_plat9.platId,
        cle_plat10: this.cle_plat10.platId,
        cle_plat11: this.cle_plat11.platId,
        cle_plat12: this.cle_plat12.platId,
        cle_plat13: this.cle_plat13.platId,
        cle_plat14: this.cle_plat14.platId,
        cle_plat15: this.cle_plat15.platId,
        cle_plat16: this.cle_plat16.platId,
        cle_plat17: this.cle_plat17.platId,
        cle_plat18: this.cle_plat18.platId,
        cle_plat19: this.cle_plat19.platId,
        cle_plat20: this.cle_plat20.platId,
        cle_plat21: this.cle_plat21.platId,
        cle_plat22: this.cle_plat22.platId,
        cle_plat23: this.cle_plat23.platId,
        cle_plat24: this.cle_plat24.platId,
        cle_plat25: this.cle_plat25.platId,
        cle_plat26: this.cle_plat26.platId,
        cle_plat27: this.cle_plat27.platId,
        cle_plat28: this.cle_plat28.platId,
        cle_plat29: this.cle_plat29.platId,
        cle_plat30: this.cle_plat30.platId,
        cle_plat31: this.cle_plat31.platId,
        cle_plat32: this.cle_plat32.platId,
        cle_plat33: this.cle_plat33.platId,
        cle_plat34: this.cle_plat34.platId,
        cle_plat35: this.cle_plat35.platId,
        cle_plat36: this.cle_plat36.platId,
        cle_plat37: this.cle_plat37.platId,
        cle_plat38: this.cle_plat38.platId,
        cle_plat39: this.cle_plat39.platId,
        cle_plat40: this.cle_plat40.platId,
        cle_plat41: this.cle_plat41.platId,
        cle_plat42: this.cle_plat42.platId,
        cle_plat43: this.cle_plat43.platId,
        cle_plat44: this.cle_plat44.platId,
        cle_plat45: this.cle_plat45.platId,
        cle_plat46: this.cle_plat46.platId,
        cle_plat47: this.cle_plat47.platId,
        cle_plat48: this.cle_plat48.platId,
        cle_plat49: this.cle_plat49.platId,
        cle_plat50: this.cle_plat50.platId,
        cle_plat51: this.cle_plat51.platId,
        cle_plat52: this.cle_plat52.platId,
        cle_plat53: this.cle_plat53.platId,
        cle_plat54: this.cle_plat54.platId,
        cle_plat55: this.cle_plat55.platId,
        cle_plat56: this.cle_plat56.platId,
        cle_plat57: this.cle_plat57.platId,
        cle_plat58: this.cle_plat58.platId,
        cle_plat59: this.cle_plat59.platId,
        cle_plat60: this.cle_plat60.platId,
        cle_plat61: this.cle_plat61.platId,
        cle_plat62: this.cle_plat62.platId,
        cle_plat63: this.cle_plat63.platId,
        cle_plat64: this.cle_plat64.platId,
        cle_plat65: this.cle_plat65.platId,
        cle_plat66: this.cle_plat66.platId,
        cle_plat67: this.cle_plat67.platId,
        cle_plat68: this.cle_plat68.platId,
      
      },
        { headers: { Authorization: `Bearer ${store.state.token}` },}
      )
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Menu publié',
          showConfirmButton: false,
          timer: 2000
        })
        window.location.reload()
      })
      .catch(error => {console.log("Menu non publié: ", error.response);
        Swal.fire({
          title: "Une erreur est survenue",
          icon: "error",
          timer: 2500,
          showConfirmButton: false,
          timerProgressBar: true
        }) 
      })
    },

  },
  created () {
            document.title = "Ajout menu";
  },

   mounted() {
  //récupération des utilisateurs
    axios.get(`http://localhost:3000/plats`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
      .then(response => {
          for(const plat of response.data.plat){
          this.allPlats.push(plat)
          }
          this.allPlats.forEach(plat => {
          this.profil.push(plat.platId)
          })     
        })
      .catch(error => {console.log('An error occurred:', error.response);})

    axios.get(`http://localhost:3000/tarif`, {
        
        },
        {headers: {Authorization: `Bearer ${store.state.token}`},}
        )
        .then(response => {
            console.log(response.data.tarif);
            for(const tarif of response.data.tarif){
            this.allTarif.push(tarif)
            }
            this.allTarif.forEach(tarif => {
            this.profil.push(tarif.tarifId)
            })     
        })
        .catch(error => {console.log('An error occurred:', error.response);})


    axios.get(`http://localhost:3000/tarif`, {
        
        },
        {headers: {Authorization: `Bearer ${store.state.token}`},}
        )
        .then(response => {
            this.semaine1Tarif = moment(String(new Date(this.allTarif[0].createdAt))).format('DD-MM-YYYY')
            this.semaine2Tarif = moment(String(new Date(this.allTarif[1].createdAt))).format('DD-MM-YYYY')
            this.semaine3Tarif = moment(String(new Date(this.allTarif[2].createdAt))).format('DD-MM-YYYY')
            this.semaine4Tarif = moment(String(new Date(this.allTarif[3].createdAt))).format('DD-MM-YYYY')
            this.semaine5Tarif = moment(String(new Date(this.allTarif[4].createdAt))).format('DD-MM-YYYY')
            this.tarifId1 = this.allTarif[0].tarifId
            
            for(const tarif of response.data.tarif){
            this.allTarif.push(tarif)
            }
            this.allTarif.forEach(tarif => {
            this.profil.push(tarif.tarifId)
            })
            
            
        })
        .catch(error => {console.log('An error occurred:', error.response);})


  },

}
</script>

<style>
.container {
  height: 100vh;
  z-index: 0;
}

.cochon{
    width: 20px;
}

.centre {
  width: 200px;
  margin: 10px auto 10px auto;
}
.fin {
  width: 200px;
  margin: 0 auto 0 65%;
}
.inpselect {
  width: 80%;
}
.titrecat {
  display: block;
  position: absolute;
  top: 2%;
  left: 80%;
  width: 10%;
}
.cochon{
  display: block;
  position: absolute;
  top: 2.5%;
  left: 90%;
  width: 20px;
}
.selecteurDate {
  width: 
  500px;
}

</style>