<template>
  <div class="container-fluid pt-5 pb-5 mt-5 mb-4">
    <div class="card">
      <div class="card-header text-center">
        <h1>Modification du menu de la semaine : {{ semaineSaisie }}</h1>


        <div class="row pt-2">
          <div class="col-3">
            <h4>Sélectionner la semaine de saisie</h4>
            <select v-model="semaineSaisie" class="form-select ml-5" aria-label="Default select example">
              <option disabled value="">Semaine</option>
              <option>{{ semaine1 }}</option>
              <option>{{ semaine2 }}</option>
              <option>{{ semaine3 }}</option>
              <option>{{ semaine4 }}</option>
            </select>  
          </div>

          <div class="col-3">
            <h4>Sélectionner la grille de prix</h4>
            <select v-model="TarifSemaine" class="form-select ml-5" aria-label="Default select example" return-object>
              <option disabled value="">{{ TarifSemaine }}</option>
              <option>{{ allTarif[0].semaine_tarif }}</option>
              <option>{{ allTarif[1].semaine_tarif }}</option>
              <option>{{ allTarif[2].semaine_tarif }}</option>
              <option>{{ allTarif[3].semaine_tarif }}</option>
            </select>  
          </div>
          

          <div class="col-3">
            <h4>Choisir la date de fin de validité des saisies</h4>
            <div class="selecteurDate">
              <b-form-datepicker id="dateValidite" v-model="dateValidite"></b-form-datepicker>
            </div>
          </div>

          <div class="col-3">
            <h4>Activation  du menu</h4>
            <div>
              <b-form-checkbox size="lg" id="menuActif" v-model="menuActif" name="menuActif" value="true" unchecked-value="false">Menu actif pour saisie</b-form-checkbox>
            </div>
          </div>

        </div>


        
        <button @click="retour()" class="btn btn-success float-right" >Retour Accueil</button>
        
        

      </div>
      <div>
        
        <button class="btn btn-primary mr-3 ml-5 mt-1 lb-1" value="importer">Importer</button>

        
        <button @click="majBd()" class="btn btn-danger ml-5 mt-1" >Mise à jour des plats</button>
        <button @click="importMenu()" class="btn btn-danger ml-5 mt-1" >import menus</button>

        <div v-if="!visible" class = "row ml-5 mr-5 mb-5 mt-2">
          <h2 class="pt-5 ml-auto mr-auto">Veuillez sélectionner votre semaine et ensuite importer votre menu pour faire les modifications</h2>
          
        </div> 


        <div class = "row ml-5 mr-5 mb-5 mt-2">
          <div  v-if="visible" class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Lundi {{ jour1 }}</h2>
            </div>
            <!-- début lundi saisie-->
            <div class = "col border text-center mt-2">
              <!-- Entrées début -->
              <h3 class="inpselect mt-2">Entrées</h3>
              <h4 class="titrecat">C</h4>
              <h4><img class="cochon" src="../assets/cochong.png"/></h4>
           
              <!-- 1° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat1[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat1[1].complet == false || cle_plat1[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat1[1].complet == true || cle_plat1[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat1[1].porc == false || cle_plat1[4] == false" class="mr-3" for="porc">⬜</label>
                  <label v-else-if="cle_plat1[1].porc == true || cle_plat1[4] == true" class="mr-3" for="porc">⬛</label>
                </div>
              </div>
              <!-- 2° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat2[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat2[1].complet == false || cle_plat2[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat2[1].complet == true || cle_plat2[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat2[1].porc == false || cle_plat2[4] == false" class="mr-3" for="porc">⬜</label>
                  <label v-else-if="cle_plat2[1].porc == true || cle_plat2[4] == true" class="mr-3" for="porc">⬛</label>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat3[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat3[1].complet == false || cle_plat3[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat3[1].complet == true || cle_plat3[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat3[1].porc == false || cle_plat3[4] == false" class="mr-3" for="porc">⬜</label>
                  <label v-else-if="cle_plat3[1].porc == true || cle_plat3[4] == true" class="mr-3" for="porc">⬛</label>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat4[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat4[1].complet == false || cle_plat4[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat4[1].complet == true || cle_plat4[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat4[1].porc == false || cle_plat4[4] == false" class="mr-3" for="porc">⬜</label>
                  <label v-else-if="cle_plat4[1].porc == true || cle_plat4[4] == true" class="mr-3" for="porc">⬛</label>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat5[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat5[1].complet == false || cle_plat5[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat5[1].complet == true || cle_plat5[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat5[1].porc == false || cle_plat5[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat5[1].porc == true || cle_plat5[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat6[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat5[1].complet == false || cle_plat6[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat6[1].complet == true || cle_plat6[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat6[1].porc == false || cle_plat6[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat6[1].porc == true || cle_plat6[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat7[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat7[1].complet == false || cle_plat7[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat7[1].complet == true || cle_plat7[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat7[1].porc == false || cle_plat7[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat7[1].porc == true || cle_plat7[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat8[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat8[1].complet == false || cle_plat8[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat8[1].complet == true || cle_plat8[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat8[1].porc == false || cle_plat8[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat8[1].porc == true || cle_plat8[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat9[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat9[1].complet == false || cle_plat9[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat9[1].complet == true || cle_plat9[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat9[1].porc == false || cle_plat9[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat9[1].porc == true || cle_plat9[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat10[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat10[1].complet == false || cle_plat10[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat10[1].complet == true || cle_plat10[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat10[1].porc == false || cle_plat10[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat10[1].porc == true || cle_plat10[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat11[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat11[1].complet == false || cle_plat11[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat11[1].complet == true || cle_plat11[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat11[1].porc == false || cle_plat11[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat11[1].porc == true || cle_plat11[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat12[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat12[1].complet == false || cle_plat12[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat12[1].complet == true || cle_plat12[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat12[1].porc == false || cle_plat12[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat12[1].porc == true || cle_plat12[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat13[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat13[1].complet == false || cle_plat13[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat13[1].complet == true || cle_plat13[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat13[1].porc == false || cle_plat13[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat13[1].porc == true || cle_plat13[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Fromage fin -->

              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat14[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
              </div>

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat15[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat15[1].complet == false || cle_plat15[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat15[1].complet == true || cle_plat15[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat15[1].porc == false || cle_plat15[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat15[1].porc == true || cle_plat15[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat16[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat16[1].complet == false || cle_plat16[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat16[1].complet == true || cle_plat16[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat16[1].porc == false || cle_plat16[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat16[1].porc == true || cle_plat16[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat17[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat17[1].complet == false || cle_plat17[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat17[1].complet == true || cle_plat17[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat17[1].porc == false || cle_plat17[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat17[1].porc == true || cle_plat17[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Dessert fin -->

              

            </div>
            <!-- Fin lundi saisie-->
          </div>
          <div v-if="visible" class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Mardi {{ jour2 }}</h2>
            </div>

            <!-- début Mardi saisie-->
            <div class = "col border text-center mt-2">
              <!-- Entrées début -->
              <h3 class="inpselect mt-2">Entrées</h3>
              <h4 class="titrecat">C</h4>
              <h4><img class="cochon" src="../assets/cochong.png"/></h4>
           
              <!-- 1° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat18[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat18[1].complet == false || cle_plat18[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat18[1].complet == true || cle_plat18[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat18[1].porc == false || cle_plat18[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat18[1].porc == true || cle_plat18[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat19[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat19[1].complet == false || cle_plat19[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat19[1].complet == true || cle_plat19[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat19[1].porc == false || cle_plat19[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat19[1].porc == true || cle_plat19[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat20[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat20[1].complet == false || cle_plat20[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat20[1].complet == true || cle_plat20[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat20[1].porc == false || cle_plat20[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat20[1].porc == true || cle_plat20[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat21[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat21[1].complet == false || cle_plat21[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat21[1].complet == true || cle_plat21[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat21[1].porc == false || cle_plat21[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat21[1].porc == true || cle_plat21[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat22[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat22[1].complet == false || cle_plat22[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat22[1].complet == true || cle_plat22[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat22[1].porc == false || cle_plat22[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat22[1].porc == true || cle_plat22[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat23[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat23[1].complet == false || cle_plat23[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat23[1].complet == true || cle_plat23[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat23[1].porc == false || cle_plat23[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat23[1].porc == true || cle_plat23[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat24[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat24[1].complet == false || cle_plat24[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat24[1].complet == true || cle_plat24[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat24[1].porc == false || cle_plat24[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat24[1].porc == true || cle_plat24[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat25[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat25[1].complet == false || cle_plat25[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat25[1].complet == true || cle_plat25[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat25[1].porc == false || cle_plat25[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat25[1].porc == true || cle_plat25[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat26[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat26[1].complet == false || cle_plat26[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat26[1].complet == true || cle_plat26[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat26[1].porc == false || cle_plat26[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat26[1].porc == true || cle_plat26[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat27[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat27[1].complet == false || cle_plat27[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat27[1].complet == true || cle_plat27[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat27[1].porc == false || cle_plat27[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat27[1].porc == true || cle_plat27[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat28[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat28[1].complet == false || cle_plat28[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat28[1].complet == true || cle_plat28[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat28[1].porc == false || cle_plat28[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat28[1].porc == true || cle_plat28[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat29[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat29[1].complet == false || cle_plat29[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat29[1].complet == true || cle_plat29[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat29[1].porc == false || cle_plat29[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat29[1].porc == true || cle_plat29[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat30[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat30[1].complet == false || cle_plat30[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat30[1].complet == true || cle_plat30[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat30[1].porc == false || cle_plat30[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat30[1].porc == true || cle_plat30[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Fromage fin -->

              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat31[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
              </div>
              <!-- Fin Pain -->

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat32[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat32[1].complet == false || cle_plat32[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat32[1].complet == true || cle_plat32[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat32[1].porc == false || cle_plat32[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat32[1].porc == true || cle_plat32[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat33[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat33[1].complet == false || cle_plat33[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat33[1].complet == true || cle_plat33[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat33[1].porc == false || cle_plat33[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat33[1].porc == true || cle_plat33[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat34[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat34[1].complet == false || cle_plat34[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat34[1].complet == true || cle_plat34[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat34[1].porc == false || cle_plat34[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat34[1].porc == true || cle_plat34[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Dessert fin -->

              

            </div>
            <!-- Fin Mardi saisie-->

          </div>
          <div v-if="visible" class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Mercredi {{ jour3 }}</h2>
            </div>

            <!-- début Mercredi saisie-->
            <div class = "col border text-center mt-2">
              <!-- Entrées début -->
              <h3 class="inpselect mt-2">Entrées</h3>
              <h4 class="titrecat">C</h4>
              <h4><img class="cochon" src="../assets/cochong.png"/></h4>
           
              <!-- 1° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat35[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat35[1].complet == false || cle_plat35[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat35[1].complet == true || cle_plat35[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat35[1].porc == false || cle_plat35[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat35[1].porc == true || cle_plat35[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat36[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat36[1].complet == false || cle_plat36[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat36[1].complet == true || cle_plat36[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat36[1].porc == false || cle_plat36[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat36[1].porc == true || cle_plat36[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat37[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat37[1].complet == false || cle_plat37[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat37[1].complet == true || cle_plat37[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat37[1].porc == false || cle_plat37[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat37[1].porc == true || cle_plat37[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat38[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat38[1].complet == false || cle_plat38[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat38[1].complet == true || cle_plat38[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat38[1].porc == false || cle_plat38[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat38[1].porc == true || cle_plat38[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat39[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat39[1].complet == false || cle_plat39[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat39[1].complet == true || cle_plat39[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat39[1].porc == false || cle_plat39[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat39[1].porc == true || cle_plat39[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat40[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat40[1].complet == false || cle_plat40[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat40[1].complet == true || cle_plat40[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat40[1].porc == false || cle_plat40[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat40[1].porc == true || cle_plat40[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat41[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat41[1].complet == false || cle_plat41[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat41[1].complet == true || cle_plat41[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat41[1].porc == false || cle_plat41[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat41[1].porc == true || cle_plat41[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat42[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat42[1].complet == false || cle_plat42[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat42[1].complet == true || cle_plat42[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat42[1].porc == false || cle_plat42[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat42[1].porc == true || cle_plat42[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat43[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat43[1].complet == false || cle_plat43[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat43[1].complet == true || cle_plat43[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat43[1].porc == false || cle_plat43[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat43[1].porc == true || cle_plat43[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat44[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat44[1].complet == false || cle_plat44[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat44[1].complet == true || cle_plat44[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat44[1].porc == false || cle_plat44[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat44[1].porc == true || cle_plat44[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat45[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat45[1].complet == false || cle_plat45[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat45[1].complet == true || cle_plat45[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat45[1].porc == false || cle_plat45[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat45[1].porc == true || cle_plat45[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat46[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat46[1].complet == false || cle_plat46[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat46[1].complet == true || cle_plat46[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat46[1].porc == false || cle_plat46[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat46[1].porc == true || cle_plat46[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat47[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat47[1].complet == false || cle_plat47[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat47[1].complet == true || cle_plat47[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat47[1].porc == false || cle_plat47[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat47[1].porc == true || cle_plat47[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Fromage fin -->

              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat48[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
              </div>
              <!-- Fin Pain -->

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat49[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat49[1].complet == false || cle_plat49[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat49[1].complet == true || cle_plat49[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat49[1].porc == false || cle_plat49[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat49[1].porc == true || cle_plat49[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat50[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat50[1].complet == false || cle_plat50[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat50[1].complet == true || cle_plat50[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat50[1].porc == false || cle_plat50[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat50[1].porc == true || cle_plat50[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat51[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat51[1].complet == false || cle_plat51[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat51[1].complet == true || cle_plat51[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat51[1].porc == false || cle_plat51[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat51[1].porc == true || cle_plat51[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Dessert fin -->

              

            </div>
            <!-- Fin Mercredi saisie-->

          </div>
          <div v-if="visible" class = "col border border-dark">
            <div class = "card-header text-center">
              <h2>Jeudi {{ jour4 }}</h2>
            </div>

            <!-- début Jeudi saisie-->
            <div class = "col border text-center mt-2">
              <!-- Entrées début -->
              <h3 class="inpselect mt-2">Entrées</h3>
              <h4 class="titrecat">C</h4>
              <h4><img class="cochon" src="../assets/cochong.png"/></h4>
           
              <!-- 1° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat52[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat52[1].complet == false || cle_plat52[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat52[1].complet == true || cle_plat52[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat52[1].porc == false || cle_plat52[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat52[1].porc == true || cle_plat52[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat53[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat53[1].complet == false || cle_plat53[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat53[1].complet == true || cle_plat53[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat53[1].porc == false || cle_plat53[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat53[1].porc == true || cle_plat53[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat54[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat54[1].complet == false || cle_plat54[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat54[1].complet == true || cle_plat54[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat54[1].porc == false || cle_plat54[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat54[1].porc == true || cle_plat54[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix entrées -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat55[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat55[1].complet == false || cle_plat55[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat55[1].complet == true || cle_plat55[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat55[1].porc == false || cle_plat55[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat55[1].porc == true || cle_plat55[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Entrées fin  -->

              <!-- Plats début -->
              <h3 class="inpselect mt-2">Plats</h3>
           
              <!-- 1° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat56[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat56[1].complet == false || cle_plat56[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat56[1].complet == true || cle_plat56[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat56[1].porc == false || cle_plat56[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat56[1].porc == true || cle_plat56[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat57[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat57[1].complet == false || cle_plat57[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat57[1].complet == true || cle_plat57[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat57[1].porc == false || cle_plat57[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat57[1].porc == true || cle_plat57[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat58[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat58[1].complet == false || cle_plat58[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat58[1].complet == true || cle_plat58[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat58[1].porc == false || cle_plat58[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat58[1].porc == true || cle_plat58[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 4° choix Plats -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat59[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat59[1].complet == false || cle_plat59[3] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat59[1].complet == true || cle_plat59[3] == true" class="mr-3" for="complet">⬛</label>
                  <label v-if="cle_plat59[1].porc == false || cle_plat59[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat59[1].porc == true || cle_plat59[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Plats fin -->
              
              
              <!-- Garniture début -->
              <h3 class="inpselect mt-2">Garniture</h3>
           
              <!-- 1° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat60[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat60[1].complet == false || cle_plat60[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat60[1].complet == true || cle_plat60[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat60[1].porc == false || cle_plat60[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat60[1].porc == true || cle_plat60[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat61[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat61[1].complet == false || cle_plat61[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat61[1].complet == true || cle_plat61[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat61[1].porc == false || cle_plat61[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat61[1].porc == true || cle_plat61[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Garniture -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat62[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat62[1].complet == false || cle_plat62[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat62[1].complet == true || cle_plat62[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat62[1].porc == false || cle_plat62[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat62[1].porc == true || cle_plat62[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Garniture fin -->

              <!-- Fromage début -->
              <h3 class="inpselect mt-2">Fromage</h3>
           
              <!-- 1° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat63[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat63[1].complet == false || cle_plat63[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat63[1].complet == true || cle_plat63[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat63[1].porc == false || cle_plat63[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat63[1].porc == true || cle_plat63[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Fromage -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat64[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat64[1].complet == false || cle_plat64[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat64[1].complet == true || cle_plat64[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat64[1].porc == false || cle_plat64[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat64[1].porc == true || cle_plat64[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              
              <!-- Fromage fin -->


              <!-- Début Pain -->
              <h3 class="inpselect mt-2">Pain</h3>
           
              <!-- 1° choix Pain -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat65[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
              </div>
              <!-- Fin Pain -->

              <!-- Dessert début -->
              <h3 class="inpselect mt-2">Dessert</h3>
           
              <!-- 1° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat66[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat66[1].complet == false || cle_plat66[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat66[1].complet == true || cle_plat66[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat66[1].porc == false || cle_plat66[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat66[1].porc == true || cle_plat66[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 2° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat67[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat67[1].complet == false || cle_plat67[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat68[1].complet == true || cle_plat67[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat67[1].porc == false || cle_plat67[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat67[1].porc == true || cle_plat67[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- 3° choix Dessert -->
              <div class="row justify-content-between pt-2">
                <v-select v-model="cle_plat68[1]" class="inpselect" label="nom_plat" :options="allPlats" return-object></v-select>
                <div class="vide">
                  <label v-if="cle_plat68[1].complet == false || cle_plat68[3] == false" class="mr-3" for="complet"></label>
                  <label v-else-if="cle_plat68[1].complet == true || cle_plat68[3] == true" class="mr-3" for="complet"></label>
                  <label v-if="cle_plat68[1].porc == false || cle_plat68[4] == false" class="mr-3" for="complet">⬜</label>
                  <label v-else-if="cle_plat68[1].porc == true || cle_plat68[4] == true" class="mr-3" for="complet">⬛</label>
                </div>
              </div>
              <!-- Dessert fin -->

              

            </div>
            <!-- Fin Jeudi saisie-->

          </div>



        </div>




        <div>



          <!-- Ajout de la fenêtre modale en bootstrap -->


        <div>
          <!-- bouton pour l'ouverture de la création d'un plat -->
          <b-button class="btn-success float-left ml-5 mb-4" v-b-modal.modal-prevent-closing>Création d'un plat</b-button>
            <b-modal
              id="modal-prevent-closing"
              ref="modal"
              title="Création d'un plat"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit" >
                <div>
                  <select v-model="categorie">
                    <option disabled value="">Choisissez la catégorie de plat</option>
                    <option>Entrées</option>
                    <option>Main Course</option>
                    <option>Accompagnements</option>
                    <option>Fromages</option>
                    <option>pain</option>
                    <option>Desserts</option>
                  </select>
                  <br>
                </div>
                <div class="form-group mt-3">
                  <label class="ml-2" for="nom_plat">Entrez le nom du plat</label>
                  <input v-model="nom_plat" type="text" id="nom_plat" class="form-control">
                </div>
                <div class="form-group">
                  <label class="ml-2 case" for="complet">Plat complet</label>
                  <input v-model="complet" type="checkbox" id="complet" class="form-control case">
                </div>
                <div class="form-group">
                  <label class="ml-2 case" for="porc">Contient du porc</label>
                  <input v-model="porc" type="checkbox" id="porc" class="form-control case">
                </div>
              </form>
              <template #modal-footer>
                <div class="w-100">
                  <b-button
                    variant="success"
                    size="xl"
                    class="float-right"
                    @click.prevent="creationPlat()"
                  >
                    Enregister le plat
                  </b-button>
                </div>
              </template>
            </b-modal>
            <!-- bouton pour la validation du menu -->
            <button class="btn btn-primary float-right mr-5 mb-4" @click.prevent="updateMenu()">Mettre à jour le menu</button>
        </div>


        </div>
      </div>

        
    </div>
    
  </div>




</template>

<script>
import axios from 'axios'
import store from '../store/index'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'vue-select/dist/vue-select.css';



export default {
  
  name: 'ModifMenu',

  data () {
    return{
      affichage: false,
      categoriePlat: ['Entrées', 'Main Course', 'Accompagnements', 'Fromages', 'Desserts'],
      categorie: '',
      dateValidite: '',
      menuActif: 'false',
      complet: false,
      porc: false,
      visible: false,
      name: '',
      nameState: null,
      submittedNames: [],
      semaine: '',
      semaineSaisie: '',
      revele: false,
      nom_plat: "",
      liste: [],
      menuPlat: [],
      allPlats: [],
      allmenus: [],
      allMenus: [],
      menuTraitement: [],
      plat: '',
      vartest: '',
      allTarif: [],
      tarifId1: '',
      semaine1Tarif: '',
      semaine2Tarif: '',
      semaine3Tarif: '',
      semaine4Tarif: '',
      semaine5Tarif: '',
      TarifSemaine: '',      
      semaine1: moment(String(new Date())).format('W-YYYY'),
      semaine1Jour: moment().isoWeekday(this.semaine1),
      jour1: '',
      jour2: '',
      jour3: '',
      jour4: '',
      semaine2: moment(String(new Date())).add(7, 'days').format('W-YYYY'),
      semaine3: moment(String(new Date())).add(14, 'days').format('W-YYYY'),
      semaine4: moment(String(new Date())).add(21, 'days').format('W-YYYY'),
      cle_plat1: [],
      cle_plat2: [],
      cle_plat3: [],
      cle_plat4: [],
      cle_plat5: [],
      cle_plat6: [],
      cle_plat7: [],
      cle_plat8: [],
      cle_plat9: [],
      cle_plat10: [],
      cle_plat11: [],
      cle_plat12: [],
      cle_plat13: [],
      cle_plat14: [],
      cle_plat15: [],
      cle_plat16: [],
      cle_plat17: [],
      cle_plat18: [],
      cle_plat19: [],
      cle_plat20: [],
      cle_plat21: [],
      cle_plat22: [],
      cle_plat23: [],
      cle_plat24: [],
      cle_plat25: [],
      cle_plat26: [],
      cle_plat27: [],
      cle_plat28: [],
      cle_plat29: [],
      cle_plat30: [],
      cle_plat31: [],
      cle_plat32: [],
      cle_plat33: [],
      cle_plat34: [],
      cle_plat35: [],
      cle_plat36: [],
      cle_plat37: [],
      cle_plat38: [],
      cle_plat39: [],
      cle_plat40: [],
      cle_plat41: [],
      cle_plat42: [],
      cle_plat43: [],
      cle_plat44: [],
      cle_plat45: [],
      cle_plat46: [],
      cle_plat47: [],
      cle_plat48: [],
      cle_plat49: [],
      cle_plat50: [],
      cle_plat51: [],
      cle_plat52: [],
      cle_plat53: [],
      cle_plat54: [],
      cle_plat55: [],
      cle_plat56: [],
      cle_plat57: [],
      cle_plat58: [],
      cle_plat59: [],
      cle_plat60: [],
      cle_plat61: [],
      cle_plat62: [],
      cle_plat63: [],
      cle_plat64: [],
      cle_plat65: [],
      cle_plat66: [],
      cle_plat67: [],
      cle_plat68: [],

    }

  },

  methods: {

    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
      },
      onChange(event) {
            console.log(event.target.value)
        },
      resetModal() {
        this.name = ''
        this.nameState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        // Push the name to submitted names
        this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      },
    creationPlat() {
          if (!this.checkFormValidity()) {
          return
        }

          axios.post('http://localhost:3000/plats', {
            categorie: this.categorie,
            complet: this.complet,
            porc: this.porc,
            nom_plat: this.nom_plat,
            
          },
            { headers: { Authorization: `Bearer ${store.state.token}` },}
          )
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Plat créé',
              showConfirmButton: false,
              timer: 2000
            })
            this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
        })
          })
          .catch(error => {console.log("Menu non publié: ", error.response);
            Swal.fire({
              title: "Une erreur est survenue sur la création du plat",
              icon: "error",
              timer: 2500,
              showConfirmButton: false,
              timerProgressBar: true
            }) 
          }) 
 
        }, 
    retour(){
      this.$router.push('/connecter')
    },
    majBd(){
      this.allPlats = [],
      axios.get(`http://localhost:3000/plats`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
        .then(response => {
          for(const plat of response.data.plat){
            this.allPlats.push(plat)}
            Swal.fire({
              icon: 'success',
                title: 'Mise à jour de la base de données',
                showConfirmButton: false,
                timer: 1500 })
          this.allPlats.forEach(plat => {
          this.profil.push(plat.platId)
          })  
        })
      .catch(error => {console.log('An error occurred:', error.response);})
    },

    JourMenu(){
        if(this.semaineSaisie == this.semaine1){
          //Gestion des dates pour l'affichage dans la saisie des menus
          switch (this.semaine1Jour) {
            case 1:
              this.jour1 = moment(String(new Date())).format('Do MMMM');
              this.jour2 = moment(String(new Date())).add(+1 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+2 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+3 , 'days').format('DD/MM/YY');
              break;
            case 2: // Si le jours de la semaine est mardi
              this.jour1 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
              this.jour2 = moment(String(new Date())).add(+0 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+1 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+2 , 'days').format('DD/MM/YY');
              break;
            case 3: // Si le jours de la semaine est mercredi
            this.jour1 = moment(String(new Date())).add(-2 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+0 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+1 , 'days').format('DD/MM/YY');
              break;
            case 4: // Si le jours de la semaine est jeudi
            this.jour1 = moment(String(new Date())).add(-3 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(-2 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+0 , 'days').format('DD/MM/YY');
              break;
            case 5: // Si le jours de la semaine est vendredi
            this.jour1 = moment(String(new Date())).add(-4 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(-3 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(-2 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(-1 , 'days').format('DD/MM/YY');
              break;    
            default:
              console.log('default');
          }
        } else if(this.semaineSaisie == this.semaine2) {
          //Gestion des dates pour l'affichage dans la saisie des menus
          switch (this.semaine1Jour) {
            case 1:
              this.jour1 = moment(String(new Date())).add(+7 , 'days').format('Do MMMM');
              this.jour2 = moment(String(new Date())).add(+8 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+9 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+10 , 'days').format('DD/MM/YY');
              break;
            case 2: // Si le jours de la semaine est mardi
              this.jour1 = moment(String(new Date())).add(+6 , 'days').format('DD/MM/YY');
              this.jour2 = moment(String(new Date())).add(+7 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+8 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+9 , 'days').format('DD/MM/YY');
              break;
            case 3: // Si le jours de la semaine est mercredi
            this.jour1 = moment(String(new Date())).add(+5 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+6 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+7 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+8 , 'days').format('DD/MM/YY');
              break;
            case 4: // Si le jours de la semaine est jeudi
            this.jour1 = moment(String(new Date())).add(+4 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+5 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+6 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+7 , 'days').format('DD/MM/YY');
              break;
            case 5: // Si le jours de la semaine est vendredi
            this.jour1 = moment(String(new Date())).add(+3 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+4 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+5 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+6 , 'days').format('DD/MM/YY');
              break;    
            default:
              console.log('default');
          }
        } else if(this.semaineSaisie == this.semaine3){
          //Gestion des dates pour l'affichage dans la saisie des menus
          switch (this.semaine1Jour) {
            case 1:
              this.jour1 = moment(String(new Date())).add(+14 , 'days').format('Do MMMM');
              this.jour2 = moment(String(new Date())).add(+15 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+16 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+17 , 'days').format('DD/MM/YY');
              break;
            case 2: // Si le jours de la semaine est mardi
              this.jour1 = moment(String(new Date())).add(+13 , 'days').format('DD/MM/YY');
              this.jour2 = moment(String(new Date())).add(+14 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+15 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+16 , 'days').format('DD/MM/YY');
              break;
            case 3: // Si le jours de la semaine est mercredi
            this.jour1 = moment(String(new Date())).add(+12 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+13 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+14 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+15 , 'days').format('DD/MM/YY');
              break;
            case 4: // Si le jours de la semaine est jeudi
            this.jour1 = moment(String(new Date())).add(+11 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+12 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+13 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+14 , 'days').format('DD/MM/YY');
              break;
            case 5: // Si le jours de la semaine est vendredi
            this.jour1 = moment(String(new Date())).add(+10 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+11 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+12 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+13 , 'days').format('DD/MM/YY');
              break;    
            default:
              console.log('default');
          }
        } else if(this.semaineSaisie == this.semaine4){
          //Gestion des dates pour l'affichage dans la saisie des menus
          switch (this.semaine1Jour) {
            case 1:
              this.jour1 = moment(String(new Date())).add(+21 , 'days').format('Do MMMM');
              this.jour2 = moment(String(new Date())).add(+22 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+23 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+24 , 'days').format('DD/MM/YY');
              break;
            case 2: // Si le jours de la semaine est mardi
              this.jour1 = moment(String(new Date())).add(+20 , 'days').format('DD/MM/YY');
              this.jour2 = moment(String(new Date())).add(+21 , 'days').format('DD/MM/YY');
              this.jour3 = moment(String(new Date())).add(+22 , 'days').format('DD/MM/YY');
              this.jour4 = moment(String(new Date())).add(+23 , 'days').format('DD/MM/YY');
              break;
            case 3: // Si le jours de la semaine est mercredi
            this.jour1 = moment(String(new Date())).add(+19 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+20 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+21 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+22 , 'days').format('DD/MM/YY');
              break;
            case 4: // Si le jours de la semaine est jeudi
            this.jour1 = moment(String(new Date())).add(+18 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+19 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+20 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+21 , 'days').format('DD/MM/YY');
              break;
            case 5: // Si le jours de la semaine est vendredi
            this.jour1 = moment(String(new Date())).add(+17 , 'days').format('DD/MM/YY');
            this.jour2 = moment(String(new Date())).add(+18 , 'days').format('DD/MM/YY');
            this.jour3 = moment(String(new Date())).add(+19 , 'days').format('DD/MM/YY');
            this.jour4 = moment(String(new Date())).add(+20 , 'days').format('DD/MM/YY');
              break;    
            default:
              console.log('default');
          }
        }
      },


    importMenu(){
      //  /!\ pour l'instant la requete renvoie tous les menus existants
      // à implémenter : passer la semaine en argument pour retourner 1 seul menu
      console.log(this.semaineSaisie);
      axios.get(`http://localhost:3000/menu/${this.semaineSaisie}`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
        .then(response => {
          this.visible = true
          this.TarifSemaine = response.data.menus[0].tarif_menu
          this.allMenus = []
          this.allMenusImport = response.data.menus[0]
          // récupération et tri des données du menu
          let menuTraitement = []
          menuTraitement=(Object.values(this.allMenusImport).slice(5,73));
          // ;[menuTraitement[0], menuTraitement[1]] = [menuTraitement[1], menuTraitement[0]]
          console.log(menuTraitement)
          
          // ajout du menu
          let menuTemp = []
          for (let i = 0; i < menuTraitement.length; i++){
            console.log("Plat en cours menu traitement :" + menuTraitement[i])
            //console.log(menuTraitement[i])
            if (typeof menuTraitement[i] === 'number'){
              for (let j in this.allPlats){
                console.log("deuxieme for");
                if (menuTraitement[i] == this.allPlats[j].platId){
                  console.log("plat id : " + this.allPlats[j].platId)
                  console.log("Nom du plat : " + this.allPlats[j].nom_plat)
                  console.log("Catégorie : " + this.allPlats[j].categorie)
                  console.log("Complet : " + this.allPlats[j].complet)
                  console.log("Porc : " + this.allPlats[j].porc)
                  
                  menuTemp.push([
                                            this.allPlats[j].platId,
                                            this.allPlats[j].nom_plat,
                                            this.allPlats[j].categorie,
                                            this.allPlats[j].complet,
                                            this.allPlats[j].porc])
                }
                
              }
            } else if (typeof menuTraitement[i] != 'number'){
              menuTemp.push([
                                            0,
                                            'vide',
                                            'videcategorie',
                                            false,
                                            false])
            }
          }
          console.log('le push est : ' + menuTemp);          
          this.allMenus.push(menuTemp)
          console.log('apres le push ' + this.allMenus);
          this.cle_plat1 = this.allMenus[0][0]
          this.cle_plat2 = this.allMenus[0][1]
          this.cle_plat3 = this.allMenus[0][2]
          this.cle_plat4 = this.allMenus[0][3]
          this.cle_plat5 = this.allMenus[0][4]
          this.cle_plat6 = this.allMenus[0][5]
          this.cle_plat7 = this.allMenus[0][6]
          this.cle_plat8 = this.allMenus[0][7]
          this.cle_plat9 = this.allMenus[0][8]
          this.cle_plat10 = this.allMenus[0][9]
          this.cle_plat11 = this.allMenus[0][10]
          this.cle_plat12 = this.allMenus[0][11]
          this.cle_plat13 = this.allMenus[0][12]
          this.cle_plat14 = this.allMenus[0][13]
          this.cle_plat15 = this.allMenus[0][14]
          this.cle_plat16 = this.allMenus[0][15]
          this.cle_plat17 = this.allMenus[0][16]
          this.cle_plat18 = this.allMenus[0][17]
          this.cle_plat19 = this.allMenus[0][18]
          this.cle_plat20 = this.allMenus[0][19]
          this.cle_plat21 = this.allMenus[0][20]
          this.cle_plat22 = this.allMenus[0][21]
          this.cle_plat23 = this.allMenus[0][22]
          this.cle_plat24 = this.allMenus[0][23]
          this.cle_plat25 = this.allMenus[0][24]
          this.cle_plat26 = this.allMenus[0][25]
          this.cle_plat27 = this.allMenus[0][26]
          this.cle_plat28 = this.allMenus[0][27]
          this.cle_plat29 = this.allMenus[0][28]
          this.cle_plat30 = this.allMenus[0][29]
          this.cle_plat31 = this.allMenus[0][30]
          this.cle_plat32 = this.allMenus[0][31]
          this.cle_plat33 = this.allMenus[0][32]
          this.cle_plat34 = this.allMenus[0][33]
          this.cle_plat35 = this.allMenus[0][34]
          this.cle_plat36 = this.allMenus[0][35]
          this.cle_plat37 = this.allMenus[0][36]
          this.cle_plat38 = this.allMenus[0][37]
          this.cle_plat39 = this.allMenus[0][38]
          this.cle_plat40 = this.allMenus[0][39]
          this.cle_plat41 = this.allMenus[0][40]
          this.cle_plat42 = this.allMenus[0][41]
          this.cle_plat43 = this.allMenus[0][42]
          this.cle_plat44 = this.allMenus[0][43]
          this.cle_plat45 = this.allMenus[0][44]
          this.cle_plat46 = this.allMenus[0][45]
          this.cle_plat47 = this.allMenus[0][46]
          this.cle_plat48 = this.allMenus[0][47]
          this.cle_plat49 = this.allMenus[0][48]
          this.cle_plat50 = this.allMenus[0][49]
          this.cle_plat51 = this.allMenus[0][50]
          this.cle_plat52 = this.allMenus[0][51]
          this.cle_plat53 = this.allMenus[0][52]
          this.cle_plat54 = this.allMenus[0][53]
          this.cle_plat55 = this.allMenus[0][54]
          this.cle_plat56 = this.allMenus[0][55]
          this.cle_plat57 = this.allMenus[0][56]
          this.cle_plat58 = this.allMenus[0][57]
          this.cle_plat59 = this.allMenus[0][58]
          this.cle_plat60 = this.allMenus[0][59]
          this.cle_plat61 = this.allMenus[0][60]
          this.cle_plat62 = this.allMenus[0][61]
          this.cle_plat63 = this.allMenus[0][62]
          this.cle_plat64 = this.allMenus[0][63]
          this.cle_plat65 = this.allMenus[0][64]
          this.cle_plat66 = this.allMenus[0][65]
          this.cle_plat67 = this.allMenus[0][66]
          this.cle_plat68 = this.allMenus[0][67]
        })
        
        
      .catch(error => {console.log('erreur import menu:', error.response);})

      setTimeout(this.JourMenu, 600)
    },

    updateMenu(){
      console.log(this.cle_plat1[1].platId);
      axios.put(`http://localhost:3000/menu/${this.semaineSaisie}`, {
       
        menuActif: this.menuActif,
        tarif_menu: this.TarifSemaine,
        date_validite: this.dateValidite,
        cle_plat1: this.cle_plat1[1].platId,
        cle_plat2: this.cle_plat2[1].platId,
        cle_plat3: this.cle_plat3[1].platId,
        cle_plat4: this.cle_plat4[1].platId,
        cle_plat5: this.cle_plat5[1].platId,
        cle_plat6: this.cle_plat6[1].platId,
        cle_plat7: this.cle_plat7[1].platId,
        cle_plat8: this.cle_plat8[1].platId,
        cle_plat9: this.cle_plat9[1].platId,
        cle_plat10: this.cle_plat10[1].platId,
        cle_plat11: this.cle_plat11[1].platId,
        cle_plat12: this.cle_plat12[1].platId,
        cle_plat13: this.cle_plat13[1].platId,
        cle_plat14: this.cle_plat14[1].platId,
        cle_plat15: this.cle_plat15[1].platId,
        cle_plat16: this.cle_plat16[1].platId,
        cle_plat17: this.cle_plat17[1].platId,
        cle_plat18: this.cle_plat18[1].platId,
        cle_plat19: this.cle_plat19[1].platId,
        cle_plat20: this.cle_plat20[1].platId,
        cle_plat21: this.cle_plat21[1].platId,
        cle_plat22: this.cle_plat22[1].platId,
        cle_plat23: this.cle_plat23[1].platId,
        cle_plat24: this.cle_plat24[1].platId,
        cle_plat25: this.cle_plat25[1].platId,
        cle_plat26: this.cle_plat26[1].platId,
        cle_plat27: this.cle_plat27[1].platId,
        cle_plat28: this.cle_plat28[1].platId,
        cle_plat29: this.cle_plat29[1].platId,
        cle_plat30: this.cle_plat30[1].platId,
        cle_plat31: this.cle_plat31[1].platId,
        cle_plat32: this.cle_plat32[1].platId,
        cle_plat33: this.cle_plat33[1].platId,
        cle_plat34: this.cle_plat34[1].platId,
        cle_plat35: this.cle_plat35[1].platId,
        cle_plat36: this.cle_plat36[1].platId,
        cle_plat37: this.cle_plat37[1].platId,
        cle_plat38: this.cle_plat38[1].platId,
        cle_plat39: this.cle_plat39[1].platId,
        cle_plat40: this.cle_plat40[1].platId,
        cle_plat41: this.cle_plat41[1].platId,
        cle_plat42: this.cle_plat42[1].platId,
        cle_plat43: this.cle_plat43[1].platId,
        cle_plat44: this.cle_plat44[1].platId,
        cle_plat45: this.cle_plat45[1].platId,
        cle_plat46: this.cle_plat46[1].platId,
        cle_plat47: this.cle_plat47[1].platId,
        cle_plat48: this.cle_plat48[1].platId,
        cle_plat49: this.cle_plat49[1].platId,
        cle_plat50: this.cle_plat50[1].platId,
        cle_plat51: this.cle_plat51[1].platId,
        cle_plat52: this.cle_plat52[1].platId,
        cle_plat53: this.cle_plat53[1].platId,
        cle_plat54: this.cle_plat54[1].platId,
        cle_plat55: this.cle_plat55[1].platId,
        cle_plat56: this.cle_plat56[1].platId,
        cle_plat57: this.cle_plat57[1].platId,
        cle_plat58: this.cle_plat58[1].platId,
        cle_plat59: this.cle_plat59[1].platId,
        cle_plat60: this.cle_plat60[1].platId,
        cle_plat61: this.cle_plat61[1].platId,
        cle_plat62: this.cle_plat62[1].platId,
        cle_plat63: this.cle_plat63[1].platId,
        cle_plat64: this.cle_plat64[1].platId,
        cle_plat65: this.cle_plat65[1].platId,
        cle_plat66: this.cle_plat66[1].platId,
        cle_plat67: this.cle_plat67[1].platId,
        cle_plat68: this.cle_plat68[1].platId,

      
      },
        { headers: { Authorization: `Bearer ${store.state.token}` },}
      )
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Menu mis à jour',
          showConfirmButton: false,
          timer: 2000
        })
        setTimeout(console.log('validation du menu'), 20000)
        window.location.reload()
        
        
      })
      .catch(error => {console.log("Menu non mis à jour: ", error.response);
        Swal.fire({
          title: "Une erreur est survenue",
          icon: "error",
          timer: 2500,
          showConfirmButton: false,
          timerProgressBar: true
        }) 
      })
      
    },

    

  },
  created () {
            document.title = "Ajout menu";
  },

   mounted() {
  //récupération des plats
    axios.get(`http://localhost:3000/plats`, {
      headers: {Authorization: `Bearer ${store.state.token}`}}
      )
        .then(response => {
          for(const plat of response.data.plat){
          this.allPlats.push(plat)
          }
          this.allPlats.forEach(plat => {
          this.profil.push(plat.platId)
          })     
        })
      .catch(error => {console.log('An error occurred:', error.response);})


      axios.get(`http://localhost:3000/tarif`, {
        
        },
        {headers: {Authorization: `Bearer ${store.state.token}`},}
        )
        .then(response => {
            console.log(response.data.tarif);
            for(const tarif of response.data.tarif){
            this.allTarif.push(tarif)
            }
            this.allTarif.forEach(tarif => {
            this.profil.push(tarif.tarifId)
            })     
        })
        .catch(error => {console.log('An error occurred:', error.response);})


        
    axios.get(`http://localhost:3000/tarif`, {
        
        },
        {headers: {Authorization: `Bearer ${store.state.token}`},}
        )
        .then(response => {
            this.semaine1Tarif = moment(String(new Date(this.allTarif[0].createdAt))).format('DD-MM-YYYY')
            this.semaine2Tarif = moment(String(new Date(this.allTarif[1].createdAt))).format('DD-MM-YYYY')
            this.semaine3Tarif = moment(String(new Date(this.allTarif[2].createdAt))).format('DD-MM-YYYY')
            this.semaine4Tarif = moment(String(new Date(this.allTarif[3].createdAt))).format('DD-MM-YYYY')
            this.semaine5Tarif = moment(String(new Date(this.allTarif[4].createdAt))).format('DD-MM-YYYY')
            this.tarifId1 = this.allTarif[0].tarifId
            
            for(const tarif of response.data.tarif){
            this.allTarif.push(tarif)
            }
            this.allTarif.forEach(tarif => {
            this.profil.push(tarif.tarifId)
            })
            
            
        })
        .catch(error => {console.log('An error occurred:', error.response);})
  },

}
</script>

<style>
.container {
  height: 100vh;
  z-index: 0;
}
/* .card {
  opacity: 0.9;
} */
.cochon{
    width: 20px;
}
/* .home {
  display: block;
  margin: 10px 50px 10px auto;
} */
.centre {
  width: 200px;
  margin: 10px auto 10px auto;
}
.fin {
  width: 200px;
  margin: 0 auto 0 65%;
}
.inpselect {
  width: 80%;
}
.titrecat {
  display: block;
  position: absolute;
  top: 2%;
  left: 80%;
  width: 10%;
}
.cochon{
  display: block;
  position: absolute;
  top: 2.5%;
  left: 90%;
  width: 20px;
}
.labelFond {
  background-color: rgb(212, 211, 211);
  width: 50%;
  text-align: left;
  padding-left: 10px;
}

</style>