<template>
  <div>
    <!-- /// Barre de navigation pour la version desktop et mobile \\\ -->
    <nav id="barnav" class="navbar fixed-top navbar-icon-top navbar-expand-lg navbar-dark">
      <div class="navbar-brand">
        <img src="../assets/SLTS.png" alt="Logo SLTS" class="logo">
      </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav pl-5 mr-auto">
          <!-- lien vers la page d'accueil pour tout les utilisateurs -->
          <li class="nav-item" @click="retour()">
            <a class="nav-link" href="#">
              <i class="fa fa-home"></i>
              Home
              <span class="sr-only">(current)</span>
            </a>
          </li>
        </ul>

        <!-- lien vers la deconnection pour les utilisateurs connectés -->
        <!-- <ul v-if="$store.state.isUserLoggedIn" class="navbar-nav pr-5"> -->
        <ul class="navbar-nav pr-5">
          <li class="nav-item" @click="logout()">
            <a class="nav-link" href="#">
              Déconnecter
              <i class="fas fa-sign-out-alt"></i>
            </a>
          </li>
        </ul>    
      </div>
    </nav>
  </div>

</template>

<script>

export default {
  name: 'Header',

  methods: {
    retour(){
      this.$router.push('/connecter')
    },
    logout(){
      this.$store.dispatch('setToken', null)
      this.$store.dispatch('setUser', null)
      this.$store.dispatch('setModerateur', false)
      this.$store.dispatch('setNom', false)
      this.$store.dispatch('setPrenom', false)
      this.$router.push('/')
    },
  }
}
</script>

<style scoped>
#barnav {
  background-color: rgb(75, 73, 73);

}
h1 {
  font-size: 2vw;
  font-style: italic;
  margin: 10px 25px 10px 25px;
}
.logo {
  width: 150px;
  height: auto;
}
</style>
