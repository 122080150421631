<template>
    <div class="container misePage pt-2 pb-5">
        <div class="card  pt-4 pl-4 pr-4">
            <div class="card-header text-center">
                <div>
                    <h1>Création d'un tarif</h1>
                    <button @click="retour()" class="btn btn-success float-right" >Retour Accueil</button>
                </div>
            </div>
                <div class="card-deck mt-3 mb-3">
                    <div class="card text-center">
                        <div class="card-header pt-4">
                            <h2>Sélection d'une semaine</h2>
                            <div class="pt-4 pb-4">
                                <select v-model="semaineSaisie" class="form-select ml-5" aria-label="Default select example">
                                    <option disabled value="">Semaine</option>
                                    <option>{{ semaine1 }}</option>
                                    <option>{{ semaine2 }}</option>
                                    <option>{{ semaine3 }}</option>
                                    <option>{{ semaine4 }}</option>
                                    <option>{{ semaine5 }}</option>
                                    <option>{{ semaine6 }}</option>
                                    <option>{{ semaine7 }}</option>
                                    <option>{{ semaine8 }}</option>
                                </select>   
                            </div>
                        </div>
                        <div class="card-body text-center" >
                            <!-- gestion des tarifs -->
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h3 class="mt-2">Type de plat</h3>
                                </div>
                                <div class="col-2">
                                    <h4 class="mt-2">Tarif</h4>
                                </div>
                            </div>
                            <!-- Tarif pour les entrées -->
                            <div class="row pt-2">
                                <div class="col-3"></div>
                                <div class="col-3 border border-dark rounded">
                                    <h5 class="text-left ml-5 mt-1">Entrée</h5>
                                </div>
                                <div class="col-2 form-outline">
                                    <input class="pl-4 mt-1" type="text" v-model="prix_entree">
                                </div>
                            </div>
                            <!-- Tarif pour le Plat principal -->
                            <div class="row pt-2">
                                <div class="col-3"></div>
                                <div class="col-3 border border-dark rounded">
                                    <h5 class="text-left ml-5 mt-1">Plat principal</h5>
                                </div>
                                <div class="col-2 form-outline">
                                    <input class="pl-4 mt-1" type="text" v-model="prix_plat">
                                </div>
                            </div>
                            <!-- Tarif pour le Fromage -->
                            <div class="row pt-2">
                                <div class="col-3"></div>
                                <div class="col-3 border border-dark rounded">
                                    <h5 class="text-left ml-5 mt-1">Fromage</h5>
                                </div>
                                <div class="col-2 form-outline">
                                    <input class="pl-4 mt-1" type="text" v-model="prix_fromage">
                                </div>
                            </div>
                            <!-- Tarif pour le Pain -->
                            <div class="row pt-2">
                                <div class="col-3"></div>
                                <div class="col-3 border border-dark rounded">
                                    <h5 class="text-left ml-5 mt-1">Pain</h5>
                                </div>
                                <div class="col-2 form-outline">
                                    <input class="pl-4 mt-1" type="text" v-model="prix_pain">
                                </div>
                            </div>
                            <!-- Tarif pour le Dessert -->
                            <div class="row pt-2">
                                <div class="col-3"></div>
                                <div class="col-3 border border-dark rounded">
                                    <h5 class="text-left ml-5 mt-1">Dessert</h5>
                                </div>
                                <div class="col-2 form-outline">
                                    <input class="pl-4 mt-1" type="text" v-model="prix_dessert">
                                </div>
                            </div>
                            <div v-if="erreur" class="pt-4">
                                <p class="erreur">Vous n'avez pas sélectionné de semaine</p>
                            </div>

                            <div>
                                <!-- bouton pour la validation du menu -->
                                <!-- <button class="btn btn-primary float-right mr-5 mb-4" @click.prevent="updateMenu()">Validation de la commande</button> -->
                                <button class="btn btn-primary float-center mt-5 mb-4" @click.prevent="creationTarif()">Création des tarifs pour la semaine {{ semaineSaisie }}</button>
                            </div>


                        </div>
                    </div>
                </div>
            
        </div>
        
    </div>
  
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import store from '../store/index'
import Swal from 'sweetalert2'


export default {
  name: 'CreationTarif',

  data () {
    return {
      semaineSaisie: "",
      semaine1: moment(String(new Date())).format('W-YYYY'),
      semaine2: moment(String(new Date())).add(7, 'days').format('W-YYYY'),
      semaine3: moment(String(new Date())).add(14, 'days').format('W-YYYY'),
      semaine4: moment(String(new Date())).add(21, 'days').format('W-YYYY'),
      semaine5: moment(String(new Date())).add(28, 'days').format('W-YYYY'),
      semaine6: moment(String(new Date())).add(35, 'days').format('W-YYYY'),
      semaine7: moment(String(new Date())).add(42, 'days').format('W-YYYY'),
      semaine8: moment(String(new Date())).add(49, 'days').format('W-YYYY'),
      semaine_tarif: '',
      prix_entree: '',
      prix_plat: '',
      prix_fromage: '',
      prix_dessert: '',
      prix_pain: '',
      erreur: false,
    }
  },

  methods: {
      retour(){
      this.$router.push('/connecter')
    },
    


    creationTarif(){
        if(this.semaineSaisie === null || this.semaineSaisie === "") {
            this.erreur = true
        } else {
            axios.post('http://localhost:3000/tarif', {
            semaine_tarif: this.semaineSaisie,
            prix_entree: this.prix_entree,
            prix_plat: this.prix_plat,
            prix_fromage: this.prix_fromage,
            prix_dessert: this.prix_dessert,
            prix_pain: this.prix_pain
                
            },
            { headers: { Authorization: `Bearer ${store.state.token}` },}
        )
        .then(() => {
            Swal.fire({
            icon: 'success',
            title: "Les tarifs sont publiés",
            showConfirmButton: false,
            timer: 2000
            })
            
        })
        .catch(error => {console.log("Tarifs non publiées : ", error.response);
            Swal.fire({
            title: "Une erreur est survenue",
            icon: "error",
            timer: 2500,
            showConfirmButton: false,
            timerProgressBar: true
            }) 
        })
            setTimeout(console.log('validation des tarifs'), 50000)
            window.location.reload()
        }
        
        
      
    },

  },

  
  
}
</script>

<style scoped>
.container {
  height: 86vh;
}
.card {
  opacity: 0.95;
}
.misePage {
  margin: 95px auto 0px auto;
}
.semaine {
    color: blue;
    font-weight: bold;
}
.erreur {
    color: red;
    font-weight: bold;
    font-size: 25px;
}
.menu {
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 15px;

}
</style>

