<template>
  <div class="container-fluid pt-5 pb-5 mt-5 mb-4">
    <!-- -fluid permet d'ajuster la taille au contenu -->
    <div class="card">
      <div class="card-header text-center">
        <h1>Consultation du menu</h1>
        <button @click="retour()" class="btn btn-success home mt-2" >Retour Accueil</button>
      </div>
      <div>
        <select class="form-select" aria-label="Default select example">
          <option selected>Semaine</option>
          <option value="1">Semaine 1</option>
          <option value="2">Semaine 2</option>
          <option value="3">Semaine 3</option>
          <option value="4">Semaine 4</option>
        </select> 
        <div class = "row ml-5 mr-5 mb-5 mt-2">
          <div class = "col border border-dark" v-for="item in menu" :key="item.name">
            <div class = "card-header text-center">
              <h2>{{ item.jour }}</h2>
            </div>
            <div class = "col border" v-for="plat in item.plats" :key="plat">
              <div v-if="plat.type==='Main Course'">
                <div class="d-flex justify-content-between">
                  <div class = "">
                      <h3> {{ plat.type }} </h3>
                  </div>
                  <div class="row mr-1">
                    <div class="mr-3">
                      <h4>C</h4>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-between" v-for="choixx in plat.choix" :key="choixx">
                  <div v-if="choixx.porc">
                    {{ choixx.nom }} <img class="cochon float-end" src="../assets/cochong.png"/>
                  </div> 
                  <div v-else>
                    {{ choixx.nom }}
                  </div>
                  <div class="row mr-4">
                    <div >
                      {{ choixx.complet }}
                    </div>
                    <!-- <div class="">
                      {{ choixx.porc }}
                    </div>  -->
                  </div>              
                </div>
              </div>
              <div v-else-if="(plat.type=='Fromages')||(plat.type=='Accompagnements')">
                <div class="d-flex justify-content-between">
                  <div class = "float-left">
                      <h3> {{ plat.type }} </h3>
                  </div>
                </div>
                <div class="row justify-content-between" v-for="choixx in plat.choix" :key="choixx">
                  <div>
                    {{ choixx.nom }}
                  </div>               
                </div>
              </div>
              <div v-else>
                <div class="d-flex justify-content-between">
                  <div class = "">
                      <h3> {{ plat.type }} </h3>
                  </div>
                  <div class="row mr-1">
                    <div class="">
                      <!-- <h4><img class="cochon float-end" src="../assets/cochong.png"/></h4> -->
                    </div>
                  </div>
                </div>
                <div class="row justify-content-between" v-for="choixx in plat.choix" :key="choixx">
                  <div v-if="choixx.porc">
                    {{ choixx.nom }} <img class="cochon float-end" src="../assets/cochong.png"/>
                  </div> 
                  <div v-else>
                    {{ choixx.nom }} 
                  </div> 
                  <!-- <div class="row mr-4">
                    <div v-if="choixx.porc">
                      <img class="cochon float-end" src="../assets/cochong.png"/>
                    </div> 
                  </div>               -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'ConsultMenu',
  data () {
    return{
      menu : [
                  {
                    jour : "Lundi", 
                    plats : [{
                      type : "Entrées",
                      choix : [
                        { nom:"Tomates",
                        "porc":false},
                        { nom:"Carrottes",
                        "porc":false},
                        { nom:"Cervelas",
                        "porc":true}]},
                        {
                      type : "Main Course",
                      choix : [
                        { nom:"Bavette",
                        "porc":false,
                        "complet":false},
                        { nom:"Saucisses",
                        "porc":true,
                        "complet":false},
                        { nom:"Couscous",
                        "porc":false,
                        "complet":true},]},
                        {
                      type : "Accompagnements",
                      choix : [
                        {nom : "Frites"},
                        {nom:"Curry de légumes"}]},
                        {
                      type : "Fromages",
                      choix : [
                        {nom:"Comté"},
                        {nom:"Camembert"}]},
                        {
                      type : "Desserts",
                      choix : [
                        {nom:"Compote de pommes",
                        "porc":false},
                        {nom:"Yaourt aromatisé aux fruits",
                        "porc":false}]}]},
                  {
                    jour : "Mardi",
                    plats : [{
                      type : "Entrées",
                      choix : [
                        { nom:"Tomates",
                        "porc":false},
                        { nom:"Carrottes",
                        "porc":false},
                        { nom:"Cervelas",
                        "porc":true}]},
                        {
                      type : "Main Course",
                      choix : [
                        { nom:"Bavette",
                        "porc":false,
                        "complet":false},
                        { nom:"Saucisses",
                        "porc":true,
                        "complet":false},
                        { nom:"Couscous",
                        "porc":false,
                        "complet":true},]},
                        {
                      type : "Accompagnements",
                      choix : [
                        {nom : "Frites"},
                        {nom:"Curry de légumes"}]},
                        {
                      type : "Fromages",
                      choix : [
                        {nom:"Comté"},
                        {nom:"Camembert"}]},
                        {
                      type : "Desserts",
                      choix : [
                        {nom:"Compote de pommes",
                        "porc":false},
                        {nom:"Yaourt aromatisé aux fruits",
                        "porc":false}]}]},
                  {
                    jour : "Mercredi",
                    plats : [{
                      type : "Entrées",
                      choix : [
                        { nom:"Tomates",
                        "porc":false},
                        { nom:"Carrottes",
                        "porc":false},
                        { nom:"Cervelas",
                        "porc":true}]},
                        {
                      type : "Main Course",
                      choix : [
                        { nom:"Bavette",
                        "porc":false,
                        "complet":false},
                        { nom:"Saucisses",
                        "porc":true,
                        "complet":false},
                        { nom:"Couscous",
                        "porc":false,
                        "complet":true},]},
                        {
                      type : "Accompagnements",
                      choix : [
                        {nom : "Frites"},
                        {nom:"Curry de légumes"}]},
                        {
                      type : "Fromages",
                      choix : [
                        {nom:"Comté"},
                        {nom:"Camembert"}]},
                        {
                      type : "Desserts",
                      choix : [
                        {nom:"Compote de pommes",
                        "porc":false},
                        {nom:"Yaourt aromatisé aux fruits",
                        "porc":false}]}]},
                  {
                    jour : "Jeudi",
                    plats : [{
                      type : "Entrées",
                      choix : [
                        { nom:"Tomates",
                        "porc":false},
                        { nom:"Carrottes",
                        "porc":false},
                        { nom:"Cervelas",
                        "porc":true}]},
                        {
                      type : "Main Course",
                      choix : [
                        { nom:"Bavette",
                        "porc":false,
                        "complet":false},
                        { nom:"Saucisses",
                        "porc":true,
                        "complet":false},
                        { nom:"Couscous",
                        "porc":false,
                        "complet":true},]},
                        {
                      type : "Accompagnements",
                      choix : [
                        {nom : "Frites"},
                        {nom:"Curry de légumes"}]},
                        {
                      type : "Fromages",
                      choix : [
                        {nom:"Comté"},
                        {nom:"Camembert"}]},
                        {
                      type : "Desserts",
                      choix : [
                        {nom:"Compote de pommes",
                        "porc":false},
                        {nom:"Yaourt aromatisé aux fruits",
                        "porc":false}]}]}]
    }
  },
  methods: {
    retour(){
      this.$router.push('/connecter')
    },

  },
  created () {
            document.title = "Consultation menu";
        }
}
</script>

<style scoped>
.container {
  height: 100vh;
}
.card {
  opacity: 0.9;
}
.cochon{
    width: 20px;
}
.home {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>